@import '../../../../../style/utilities.scss';

.PlanTable {
    padding-bottom: 100px;
}

.tableBody {
    gap: 12px;
    margin-top: 50px;
    max-width: 1360px;
    // max-width: 1100px;

    .table-col {
        .d-flex {
            min-width: 220px;
        }
    }
}

.description-parent {
    max-width: 265px;

    .description {
        min-width: 264px;
        // max-width: 265px;
        // min-width: 250px;
        background: #FFFFFF;
        box-shadow: 13px 16.5px 15.49px -10.86px rgb(0 0 0 / 35%);
        border: 1px solid #315EFB;
        border-radius: 6px;
        text-align: center;
        color: #585656;
        padding-top: 29px;
    }
}



.plan {
    // background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 217.5%), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
    // box-shadow: -4px 4px 12px -2px rgba(34, 34, 34, 0.2), inset 0px 0px 25px 5px rgba(85, 85, 85, 0.1);
    // border: 1px solid rgb(255, 255, 255);
    background: #FFFFFF;
    box-shadow: 13px 16.5px 15.49px -10.86px rgb(0 0 0 / 35%);
    border: 1px solid #315EFB;
    border-bottom: 0px;
    text-align: center;
    color: #585656;
    padding-top: 29px;
    // max-width: 198px !important;
    border-radius: 6px 6px 0px 0px;
    width: 100%;
}

/* .plan:hover */
.activeCol {
    // background-image: linear-gradient(155.16deg, rgba(78, 98, 177, 0.1) 102.08%, rgba(255, 255, 255, 0) 102.09%),
    //     linear-gradient(90deg, #FFFFFF 56.5%, rgba(255, 255, 255, 0) 90.94%);
    background: #DAE0EB;
    color: #4E62B1;

}

// .plan:hover+.ColumnBottom,
// .ColumnBottom:hover,
// .activeCol+.ColumnBottom {
//     background: #4E62B1;
//     color: white;
// }

.ChartText {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.featureIcon {
    width: 12px;
    height: 12px;
}

.tableHeading {
    color: $repeated-text-color;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 50px !important;
}

.ColumnBottom {
    width: 100% !important;
    // max-width: 198px;
    height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    color: $white;
    border: 1px solid #315EFB;
    // background: #B9C3DF;
    background: $sidebar-primary;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 0px 0px 6px 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 992px) {
    // .PlanTable {
    //     display: none;
    // }

}

@media screen and (max-width: 400px) {
    .ChartText {
        line-height: 15px;
    }
}