@import "../../../style/utilities.scss";

.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translateY(-0.25em);
}
.droppable-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}
.draggable-item {
  grid-column: span 1;
}
.mui-droupdown {
  background: red;
}
.menuIcon {
  margin-right: 5%;
  &:hover {
    background-color: gray;
    border-radius: 50%;
  }
}
.custom-toast .Toastify__toast-icon {
  color: rgba(49, 94, 251, 0.7) !important;
}

.custom-progress {
  background-color: rgba(49, 94, 251, 0.7) !important;
}

.custom-progress ::-moz-progress-bar {
  background-color: rgba(49, 94, 251, 0.7);
}

.main-sec {
  gap: 20px;
}

.trans-input {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 12px;
  color: #9c9c9c;
  width: 90%;
  border: none;
  background: $off-white;

  &:focus {
    outline: none;
  }
}

.filter-sec {
  .search-box {
    max-width: 486px;

    .search {
      z-index: 1000;
      max-width: 426px;
      border: 2px solid #315efb;
      box-shadow: $box-shadow;
      backdrop-filter: blur(75px);
      border-radius: 4px;
      padding: 0px 19px;
      // background: $off-white;
    }

    .filter-icon {
      border: 2px solid $dashboard-primary-theme-text;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      box-shadow: $box-shadow;
      background: $off-white;

      img {
        width: auto;
      }
    }
  }

  .quik-map {
    p {
      color: $repeated-text-color;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.buy-rent {
  div {
    gap: 8.25px;

    label {
      font-size: 12px;
      line-height: 12px;
      color: $repeated-text-color;
    }

    input[type="checkbox"]:checked + label {
      color: $dashboard-primary-theme-text;
    }
  }
}

.button-row {
  .button-row-left {
    gap: 11px;

    button {
      font-size: 12px;
      line-height: 12px;
      color: $dashboard-primary-theme-text;
      background: $off-white;

      &:hover {
        color: $off-white;
        background: $dashboard-primary-theme-text;
      }
    }

    .active {
      color: $off-white;
      background: $dashboard-primary-theme-text;
    }
  }

  .sort-by {
    p {
      font-size: 12px;
      line-height: 12px;
      color: $repeated-text-color;
    }

    select {
      max-width: 155px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid $dashboard-primary-theme-text !important;
      font-size: 12px;
      line-height: 12px;
      color: $dashboard-primary-theme-text;

      &:focus {
        outline: 0px;
      }
    }
  }
}

.property-feed {
  gap: 15px;
  // max-height: 50vh;
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  min-height: 35vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    border-radius: 94.5483px;
    position: relative;
  }

  &::-webkit-scrollbar-thumb {
    width: 7px;
    background: #315efb;
    border-radius: 94.5483px;
  }
}

.property-card.seen {
  .property-img {
    .card-remove-btn {
      z-index: 1;
    }

    .card-save-btn {
      z-index: 1;
    }
  }

  filter: blur(0.25px);
  //  position: absolute;
  //  inset: 0;
  //  background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.76) 6.02%, rgba(255, 255, 255, 0) 95.12%);
}

.property-card.seen::after {
  content: " ";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    157.69deg,
    rgb(255 255 255 / 76%) 6.02%,
    rgba(255, 255, 255, 0) 95.12%
  );
}

.property-card {
  padding: 2px;
  margin: 2px;
  overflow: hidden;
  width: 215px;
  height: 150px;
  box-shadow: 12.2879px 15.5962px 14.6415px -10.2651px rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(18.9044px);
  border-radius: 3.78089px;
  background: $off-white;

  .property-img {
    border-radius: 1.89044px;
    overflow: hidden;
    height: 64px;

    .card-save-btn {
      padding: 5px 10px;
      gap: 3px;
      background: rgb(255 255 255 / 23%);
      border: 0.7px solid #ffffff;
      top: 3px;
      left: 3px;
      border-radius: 2px;

      p {
        font-size: 9.09573px;
        line-height: 9px;
        color: $white;
      }

      svg {
        height: 7px;
        width: 7px;
        color: $white;
      }
    }

    .card-remove-btn {
      padding: 5px 10px;
      gap: 3px;
      background: #ff3838;
      border: 0.7px solid #ffffff;
      top: 3px;
      left: 3px;
      border-radius: 2px;

      p {
        font-size: 9.09573px;
        line-height: 9px;
        color: $white;
      }

      svg {
        height: 7px;
        width: 7px;
        color: $white;
      }
    }

    .thirty_minute {
      padding: 3px 10px;
      background: $dashboard-primary-theme-text;
      // background: rgba(255, 255, 255, 0.23);
      border: 0.7px solid $white;
      // border: 0.7px solid $dashboard-primary-theme-text;
      top: 3px;
      right: 3px;
      border-radius: 2px;

      p {
        font-size: 9.09573px;
        color: $white;
      }
    }
  }

  .property-detail {
    padding: 3px;

    .property-detail-left {
      gap: 6px;

      .price {
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: $dashboard-primary-theme-text;
        border-right: 1px solid #bbbbbb;
        padding-right: 10px;
      }

      p {
        font-size: 10px;
        line-height: 9px;
        color: $lite-black;
      }

      .bed-img {
        width: 12.21px;
        height: 9.41px;
      }

      .obr {
        font-size: 9.45221px;
        line-height: 9px;
        color: #7b8191;
      }

      .card-divider {
        background: #bbbbbb;
        width: 1px;
      }
    }

    .property-detail-right {
      div {
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background: $dashboard-primary-theme-text;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      button {
        font-size: 8px;
        line-height: 7px;
        color: $dashboard-primary-theme-text;
        border-radius: 1.81915px;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

.message-sec {
  .message-box {
    background: $off-white;
    border: 2px solid $dashboard-primary-theme-text;
    box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
    border-radius: 100px;
    padding: 6px;
    padding-left: 21px;
    overflow: hidden;
  }

  button {
    font-size: 12px;
    line-height: 12px;
    border-radius: 100px;
    min-width: 121px;
    height: 38px;
    background: $dashboard-primary-theme-text;
  }
}

.AllSelPerson_page {
  //   .main_selperson_tab ul{
  width: 100%;
  //   }
  .save__btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #315efb;
    padding: 14px 65px;
  }

  .main_selperson_tab {
    ul {
      width: 50%;
    }
    .upload_btn {
      width: 100%;
      border-radius: 4px;
      border: 2px solid #9c9c9c;
      background: #fdfdfd;
      box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(75px);
      color: #9c9c9c;
    }
    .nav-tabs .nav-link {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      /* or 100% */

      text-transform: uppercase;

      color: #585656;
    }
    .nav-tabs {
      border: 0px;
      .nav-link {
        border: 0px;
      }
    }
    .nav-tabs .nav-link.active {
      // outline: none;
      // font-family: "Poppins";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 14px;
      // line-height: 12px;
      // text-transform: uppercase;
      // text-transform: uppercase;
      outline: none;
      color: #315efb;
      border-bottom: 4px solid #315efb;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
}
.card_gap {
  column-gap: 39px;
  row-gap: 24px;
}
.table__head {
  tr {
    th {
      color: white;
    }
  }
}

.doc-sign {
  max-height: 650px;
  overflow: auto;
  margin: auto;
  margin-top: 0;
}
