@import "../../../../style/utilities.scss";

.filter-body {
  color: $repeated-text-color;
  border: 1px solid $dashboard-primary-theme-text;
  box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
  border-radius: 2px;
  padding: 16px 10px;
  max-height: 75.5vh;

  .filter-by {
    // height: 47px;
    padding-bottom: 11px;
    border-bottom: 1px solid $dashboard-primary-theme-text;
  }

  .filter-heading {
    font-size: 18px;
    line-height: 18px;
  }

  .filter-form {
    margin-top: 13px;
    gap: 11px;
    font-size: 14px;
    .filter-form-row {
      display: flex;
      flex-direction: column;
      gap: 7px;
      font-size: 14px;
      .bedroom-bathroom {
        gap: 10px;
      }
    }

    .aminity-radio {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      .aminity-radio-label {
        color: $lite-black;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
      }

      .cost-radio-label {
        font-weight: 300;
        font-size: 8px;
        line-height: 12px;
        color: $lite-black;
      }

      input[type="radio"]:checked + label {
        color: black;
      }
    }
  }

  .small-heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $lite-black;
  }

  .type {
    select {
      border: 1px solid $repeated-text-color !important;
      // padding-left: 0px;
      border-radius: 2px;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .Amenities {
    row-gap: 6px;
    column-gap: 4px;

    .aminity {
      padding: 3px 5px;
      background: #ffffff;
      border: 1px solid $dashboard-primary-theme-text;
      border-radius: 120.57px;
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
    }

    .select-aminity {
      display: flex;
      align-items: center;

      .aminity-input {
        position: absolute;
        z-index: -1;
      }

      input[type="checkbox"]:checked + label {
        background: $dashboard-primary-theme-text;
        color: $white;
        border: 1px solid $white;
      }
    }
  }

  .small-in-box-div {
    display: flex;
    gap: 10px;

    .small-in-box {
      height: 22px;
      width: 83px;
      border-radius: 2px;
      border: 1px solid $repeated-text-color;
      align-items: center;
      display: flex;
      padding-left: 6px;

      input,
      p {
        max-width: 100%;
        color: $lite-black;
        font-size: inherit;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .small-in-box-cap {
      height: 22px;
      width: 30px;
      border-radius: 2px;
      border: 1px solid $repeated-text-color;
      align-items: center;
      display: flex;
      padding-left: 3px;

      input,
      p {
        max-width: 100%;
        color: $lite-black;
        font-size: inherit;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .checkbox-column {
    gap: 7px;
  }

  .lite-text {
    input {
      font-size: 8px !important;
      line-height: 9px !important;
    }
  }
}

.select-category {
  font-size: 14px !important;
  height: 24px !important;
  border-radius: 1px !important;
  svg {
    display: none;
  }
}

.price-type-text {
  color: #315efb;
  font-weight: 300;
  font-size: 8px;
  line-height: 18px;
}

.air-right-input {
  border: 1px solid rgb(78, 98, 177) !important;
  padding: 0px 4px 0px 4px;
  border-radius: 2px;
  width: 30px;
  font-size: 10px;
}

.new-week {
  display: flex;
  align-items: center;
}
