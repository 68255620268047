@import '../../../../style/utilities.scss';

.navbar {
    padding: 17px 56px;

    .logo {
        width: 203.4px;
        height: 56.89px;
    }

    button {
        padding: 20px 40px;
        border: 2px solid #FFFFFF;
        border-radius: 60rem;
    }
}