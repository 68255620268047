@import '../../../../../style/utilities.scss';


.Plan-Card {
    // color: #585656;
    // border: 1px solid white;
    // width: 265.3px;
    padding: 23px 19px 0px 19px;
    border: 1px solid #315EFB;
    // border-bottom: none;
    box-shadow: -4px 4px 12px -2px rgba(34, 34, 34, 0.2), inset 0px 0px 25px 5px rgba(85, 85, 85, 0.1);
    border-radius: 6px 6px 0px 0px;
    position: relative;
    transition: all 0.3s ease-in-out;
    flex-direction: column;

    .card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        color: $repeated-text-color;
    }

    .card-description {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $lite-black;
    }

    .crad-price {
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        margin-top: 16px;
        color: $lite-black;
    }

    .see-all-btn {
        box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
        border-radius: 100px;
        height: 24px;
        width: 196px;
        margin-top: 22px;
        margin-bottom: 8px;
        text-transform: uppercase;
        color: $repeated-text-color;
        text-align: center;
        border: 1px solid white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .featureIcon {
        width: 12px;
        height: 12px;
    }

    .features {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-transform: capitalize;
        color: $repeated-text-color;
    }
}

.small-card {
    min-height: 410px;
    background: #FFFFFF;
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(20px);
    // border-radius: 4px;
}

.active-card {
    min-height: 453px;
    background: #DAE0EB;
}

.card-bottom-btn {
    width: 100% !important;
    height: 40px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: $white;
    background: #315EFB;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 0px 0px 6px 6px;
    width: 265.3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: auto;
    }

}