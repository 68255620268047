@import "../../../style/_utilities.scss";

// .PropertyDetailS_section{
//     width: 100%;
//     max-height: 85vh;
//     overflow-y: scroll;
//     scroll-behavior: smooth;
//     padding-bottom: 30px;

// }

.main-sec {
  width: 100%;
  // border: 2px solid orange;

  .filter-search {
    // border: 2px solid red;
    width: 100%;
  }

  .main-second-sec {
    max-height: 720px;
    overflow-y: auto;
    .propertys-card {
      flex-direction: column;
      row-gap: 11px;

      .card-sec {
        // padding-right: 11px;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        max-height: 1000px;
        overflow-y: scroll;
      }
    }

    .property-detail-sec {
      overflow-x: hidden;
    }

    .nbls-sec {
      width: 150px;
      min-width: 150px;
      max-width: 150px;
      display: flex;
      flex-direction: column;
      row-gap: 11px;

      .nbls-card {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        align-items: center;
      }
    }
  }
}
