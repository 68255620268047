@import "../../../style/utilities.scss";

.current_listing_page {
  width: 100%;
  // max-height: 85vh;
  // overflow-y: scroll;
  // scroll-behavior: smooth;

  .main_user_table {
    max-height: 200px;
    overflow-y: scroll;
  }

  .curren_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 14px;
    color: $dashboard-primary-theme-text;
  }

  .remove_btn {
    width: 110px;
    height: 40px;
  }

  .longer_btN {
    font-size: 16px;
    width: 110px;
    height: 40px;
    margin-right: 14px;
  }

  .serch_droupdown {
    width: 300px;
    border: 2px solid #315efb;
  }

  table {
    margin-top: 15px;

    thead tr th {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #585656;
      padding-bottom: 0px;
    }

    tbody tr {
      background: #fdfdfd;
      outline: 1px solid #315efb;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      border-radius: 4px !important;

      .address_width {
        width: 100px;
        display: table-cell;
      }

      td {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $lite-black;

        .edit-listing {
          color: $dashboard-primary-theme-text;
          cursor: pointer;
        }

        .copy-link {
          svg {
            rotate: 313deg;
            color: $dashboard-primary-theme-text;
          }
        }

        .share-proprty-btn {
          z-index: 10000;
          width: fit-content;
          height: 15px;
          width: 15px;

          .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
            background: transparent !important;
            width: auto !important;
            height: auto !important;

            min-height: auto !important;
          }

          button {
            // background: rgba(121, 118, 118, 0.269);
            background: transparent !important;
            box-shadow: none;
            padding: 0;
            margin: 0;

            svg {
              color: $dashboard-primary-theme-text;
              font-size: 20px;
            }
          }
        }

        img {
          max-height: 50px;
          width: 65px;
          object-fit: scale-down;
        }
      }
    }
  }
  .groupView {
    overflow-y: auto;
    max-height: 70vh !important;
    overflow-x: hidden !important;
  }
  .scrollS {
    max-height: 250px;
    overflow-x: scroll;
    overflow-y: hidden;
    // overflow-y: scroll;
    scroll-behavior: smooth;
    // padding-inline-end: 24px;
    // padding-top: 15px;

    &::-webkit-scrollbar {
      height: 6.62px;
    }

    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 94.5483px;
      position: relative;
    }

    &::-webkit-scrollbar-thumb {
      height: 4.73px;
      background: #315efb;
      border-radius: 94.5483px;
      cursor: pointer;
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    thead tr th {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #585656;
      padding-bottom: 0px;
    }

    tbody {
      padding-bottom: 50px;
    }

    tbody tr {
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      border-radius: 4px !important;
      width: 99.5%;
      margin: auto;
      margin-bottom: 17px;
      height: 65px;

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(66, 106, 248, 0.723) 0%,
          rgba(49, 94, 251, 0.7596288515406162) 38%,
          rgba(50, 39, 39, 0) 100%
        );
        border: 0.94375px solid $dashboard-primary-theme-text;
        color: $white;
      }

      .address_width {
        width: 100px;
        display: table-cell;
      }

      td {
        background: transparent;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #585656;

        .delete_droupdown {
          .dropdown-menu {
            min-width: 8rem !important;
          }

          .tool-aero::after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            /* right: 49px; */
            rotate: 134deg;
            transform: rotateX(38deg);
            transform: translateY(-556%);
            border-left: 1px solid $dashboard-primary-theme-text;
            border-top: 1px solid $dashboard-primary-theme-text;
            background: white;
            left: 83px;
            bottom: 46px;
          }
        }
      }
    }

    tbody {
      display: block;
      width: 100%;
      overflow: auto;
      max-height: 60vh;
      margin-top: 15px;
    }

    tr {
      display: flex;
      align-items: center;

      .checkTh {
        max-width: 30px;
      }
    }

    td {
      flex: 1;
    }

    th {
      flex: 1;
    }
  }
}

.listing-filter {
  input {
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid $lite-black !important;
    color: $lite-black;
    font-size: 14px;
    line-height: 14px;
  }
}
