.main_lead_container{
    flex: 1;
    margin-top: 5%;
    table{
        thead{
            tr{
                border-bottom: hidden;
                border-bottom: hidden;
                th{
                    color: #585656;
                    align-items: start;
                    text-align: start;
                    justify-content: left;
                }
            }
           
        }
    }
.lead_table_container{
    max-width: 1700px;
    padding-right: calc(var(--bs-gutter-x) * .0);
    padding-left: calc(var(--bs-gutter-x) * .0);
    border-radius: 10px;
    border: 2px solid #585656;
    overflow-x: hidden;
    table{
        thead{
            display: none;
            tr{
                display: none;
                th{
                    display: none;
                }
            }
        }
        // position: relative;
        // top: -40px;
        // margin-bottom: -40px;
            tbody{
                tr{
                    border-bottom: 2px solid #585656;
                    // justify-content: center;
                    // align-items: center;
                    td{
                    padding: 1% 0% 1%;
                    }
                    .wank_td{
                        padding-left: 7%;
                    }
                    .numb_td{
                        padding-left: 7%;
                    }
                    .emai_td{
                        padding-left: 13%;
                    }
                    .lorem_td{
                        padding-left: 10%;

                    }
                }
            
        }
    }
}
}
// style={{border:"1px solid #585656", color:"#585656", borderRadius:"6px"}}
// style={{color:"#585656"}}