@import "../.././.././.././.././style/_utilities.scss";

.media_pading {
  padding: 40px 44px;

  .dading_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #315efb;
    padding-bottom: 42px;
  }

  .main_input {
    border-bottom: 0.676897px solid #546781;
    padding-bottom: 4.7px;
    margin-bottom: 40px;

    .input_name {
      padding-right: 21px;
      border-right: 0.676897px solid #546781;
      width: 70px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      padding-bottom: 3.4px;

      .input_img {
        width: 12px;
      }
    }
    .input-sec {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .form-control:focus {
      box-shadow: none;
    }
    input {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      border-radius: 0px;
      background: transparent;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .save_btn {
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #315efb;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #315efb;
    padding: 14px 48px;

    &:hover {
      box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
      color: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 20px;
      background: #315efb;
    }
  }
}
