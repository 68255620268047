@import '../../../../../style/utilities.scss';

.community-section {
    gap: 12px;
    background: #EFF3F6;
    border-radius: 60px;
    padding-top: 34px !important;
    padding-bottom: 34px !important;
    margin-bottom: 40px;

    h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 26px;
        color: $homepage-text;
    }

    .community-inner {
        gap: 30px;
    }
}

.community-card-slider-box {
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #000;
    }

    .community-card-slider {
        overflow-x: scroll;
        gap: 42px;
        scroll-behavior: smooth;
    }

}

.community-card {
    width: 257px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 7px;
    gap: 8px;

    .card-img {
        border-radius: 18px 18px 0px 0px;
        overflow: hidden;
        background: #43A4F6;
        width: 243px;
        height: 207px;

        img {
            height: 100%;
        }
    }

    .card-bottom {
        height: 41px;
        background: #43A4F6;
        border-radius: 0px 0px 18px 18px;
    }

    p {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #FFFFFF;
    }

}

.community-arrow {
    .arrow {
        top: 40%;
    }
}