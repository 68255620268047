@import "../../../style/utilities.scss";

.subscription {
  gap: 15px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  .subscription-heading {
    h3 {
      color: $repeated-text-color;
      font-weight: 500;
      font-size: 26px;
      line-height: 28px;
    }

    p {
      color: $lite-black;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.yearly-active {
  // background-image: linear-gradient(to right, rgba(255, 255, 255, 0.35), #4E62B1);
  // color: $white
  color: $white;
  width: 90px;
  height: 34px;
  background: #ffffff;
  border-radius: 2px;
  color: #315efb;
}

.monthly-active {
  // background-image: linear-gradient(to right, #4E62B1, rgba(255, 255, 255, 0.35));
  // background-image: linear-gradient(to right, #315EFB, rgba(255, 255, 255, 0.35));
  color: $white;
  width: 90px;
  height: 34px;
  background: #ffffff;
  border-radius: 2px;
  color: #315efb;
}

.subscription-tab {
  width: 190px;
  height: 40px;
  padding: 3px;
  background: #315efb;
  color: $white;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  gap: 20px;

  .tab-btn {
    width: 90px;
    height: 34px;
    text-align: center;
    text-decoration: none;
  }
}

.plancard-container {
  max-width: 1360px;
  margin: auto;
  .addon-plus {
    color: $repeated-text-color;
  }

  .empty-col {
    max-width: 265px;
  }

  .card-coloumn {
    max-width: 265px;
    padding: 0;
    // margin: 10px;
  }
}

.nbsp-modal {
  .modal-content {
    max-width: 650px;
    margin: auto;
  }
}
