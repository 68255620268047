.main_Invoice_container {
    flex: 1;
    margin-top: 5%;

    table {
        thead {

            tr {
                border-bottom: hidden;

                th {
                    color: #585656;
                    min-width: 120px; 
                    align-items: start;
                    text-align: start;
                }
            }

        }
    }

    .invoice_table-container {
        max-width: 1700px;
        padding-right: calc(var(--bs-gutter-x) * .0);
        padding-left: calc(var(--bs-gutter-x) * .0);
        border-radius: 10px;
        border: 2px solid #585656;
        overflow-x: hidden;

        table {
            thead {
                display: none;

                .tr {
                    display: none;

                    th {
                        display: none;
                    }
                }
            }

            // position: relative;
            // top: -40px;
            // margin-bottom: -40px;
            tbody {
                border-top:0px;
                tr {
                    border-bottom: 2px solid #585656;
                    td {
                        padding: 20px 0px 20px;

                        span {
                            background: #2FC40B;
                            width: 60px;
                            height: 25px;
                            border-radius: 2px;
                        }
                    }
                }

            }
        }
    }
}

// style={{border:"1px solid #585656", color:"#585656", borderRadius:"6px"}}
// style={{color:"#585656"}}