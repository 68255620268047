@import '../../../../../style/_utilities.scss';

.custumeFileLabel {
    box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
    border: 1px solid $dashboard-primary-theme-text;
    border-radius: 2px;
    background: transparent;
    overflow: hidden;
    height: 34px;
    width: 100%;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 12px;
    color: inherit;

    .file-btn {
        flex: 2;
        background: $dashboard-primary-theme-text;
        color: #fff;
        border: 1px solid #FFFFFF;

    }

    .select {
        // border: 2px solid #FFFFFF;
        flex: 3;
    }
}