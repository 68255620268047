@import "../../../../../style/utilities.scss";

.sidebar {
  // background: $sidebar-primary;
  background: $dashboard-primary-theme-text;
  // max-width: 194px;
  padding-top: 52px;
  // padding-left: 14px;
  border-radius: 0px 35px 0px 44px;
  // height: 84.8vh;
  box-shadow: -3px 0px 2px $sidebar-primary;
  transition: 0.5s ease-in-out;

  &.sidebar-disable {
    pointer-events: none;

    a {
      color: #bcbcbc !important;
    }
  }

  @media screen and (max-width: 992px) {
    position: absolute;
    z-index: 5;
  }

  .sub-sidebar {
    height: 80%;
    // min-width: 194px;
  }

  .sidebar-links {
    gap: 22px;
  }

  .one-link {
    gap: 14px;

    a {
      // white-space: nowrap;
      text-decoration: none;
      color: $white;
      text-transform: uppercase;
      font-family: "Poppins";
      font-style: normal;
      // font-weight: 400;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      padding-left: 14px;
      padding-right: 14px;
      display: block;
      position: relative;
    }

    .active-link {
      font-weight: 500;
    }

    .active-link::after {
      content: "";
      position: absolute;
      left: -3px;
      top: -2px;
      width: 3px;
      height: 19px;
      background: #ffffff;
      box-shadow: 2px 1px 4px #ffffff;
      border-radius: 0px 2px 2px 0px;
    }

    .link-icon {
      width: 18px;
      height: 18px;
    }
  }

  .switch-office {
    .switch-btn {
      button {
        font-size: 16px;
        line-height: 12px;
        color: $dashboard-primary-theme-text;
        padding: 8px 0px;
        border-radius: 4px;
      }
    }

    select {
      color: $lite-black;
      // font-weight: 300;
      // font-size: 10px;
    }
  }
}

.setting_Droupdown {
  z-index: 1;
  top: -94px;
  left: 26px;
  padding: 15px 0px;
  background: $white;

  // li a {
  p {
    font-size: 12px;
    line-height: 12px;
    color: $dashboard-primary-theme-text !important;
    padding: 5px 10px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #ffffff !important;
      background-color: $dashboard-primary-theme-text;
    }
  }

  //
}

.setting-option {
  a {
    pointer-events: none;
  }
}
