.d-flex.flex-wrap.validation.pt-2 {
    align-self: flex-start;

    .d-flex.align-items-center.me-3 {
        font-size: 14px;
    }

    .d-flex.align-items-center.me-3 svg {
        font-size: 16px;
    }

    .d-flex.align-items-center.me-3.active {
        color: #43A502;
    }
}