@import '../../../../../style/utilities.scss';

.navbar {
    padding: 17px 56px;

    .logo {
        width: 203.4px;
        height: 56.89px;
    }

    button {
        background: $home-primary;
        font-family: "Comfortaa";
        font-weight: 700;
        font-size: 1.375rem;
        color: $white;
        line-height: 1.375rem;
        // font-size: 16px;
        // line-height: 16px;
        // padding: 12px 46px;
    }
}