@import '../../../../style/_utilities.scss';

.main_personal_sec {
    padding: 34px;

    .profile_ptext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $dashboard-primary-theme-text;
    }

    .profile_pic {
        height: 80px;
        width: 80px;
        border-radius: 50%;
    }

    .uplada_btn {
        // background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
        border-radius: 2px;
        border: 2px solid #FFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #585656;
        height: 40px;
        width: 204px;
        background-color: transparent;
        padding-left: 10px;
        margin-bottom: 22px;

        .upload_img {
            width: 10px;
            width: 10px;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $dashboard-primary-theme-text;
        padding-top: 22px;
        margin-bottom: 37px !important;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;
        margin-bottom: 21px;

        .input_name {
            padding-right: 17.75px;
            border-right: 0.676897px solid #546781;
            width: 150px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.4px;

            .red-star {
                color: red;
            }
        }

        .input-sec {
            width: 100%;
        }

        .form-control {
            padding-left: 17.75px;
        }

        .form-control:focus {
            box-shadow: none;
        }

        input {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            border-radius: 0px;
            background: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

    }

    .save_btN {
        background: #FDFDFD;
        border: 2px solid $dashboard-primary-theme-text;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(75px);
        border-radius: 4px;
        height: 40px;
        width: 127px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;

        &:hover {
            background: $dashboard-primary-theme-text;
            color: #FDFDFD;
            border: 2px solid #FDFDFD;

        }
    }
}


.validation-text {
    font-size: 12px;
    color: red;
    white-space: nowrap;
    text-transform: capitalize;
}

.mui-autocomplete-select {
    .css-1q60rmi-MuiAutocomplete-endAdornment {
        display: none;
    }

    .css-l4u8b9-MuiInputBase-root-MuiInput-root:before,
    .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
        display: none;
    }


}



.custum-react-select {

    .css-1dimb5e-singleValue {
        color: $lite-black;
        font-size: 12px;
        line-height: 12px !important;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0px !important;
        padding-left: 17px !important;
        max-height: 22px !important;
    }

    .css-1u9des2-indicatorSeparator,
    .css-15lsz6c-indicatorContainer,
    .css-1xc3v61-indicatorContainer {
        display: none;
    }

    .css-1hb7zxy-IndicatorsContainer,
    .css-13cymwt-control {
        border: none;
        min-height: 0px !important;

        &:focus {
            outline: none;
        }

        &:hover {
            border: 0px !important;
        }
    }

    .css-qbdosj-Input,
    .css-166bipr-Input {
        input {
            color: $lite-black !important;
            font-size: 12px !important;
            line-height: 10px !important;
        }
    }

    .css-1nmdiq5-menu {

        div {
            background: $dashboard-primary-theme-text;
            color: #FFF;
            cursor: pointer;
        }
    }

    // .css-1dimb5e-singleValue {
    //     color: green;
    // }
    .css-qbdosj-Input {
        margin: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        // max-height: 10px !important;
    }

    .css-t3ipsp-control {
        border: 0px !important;
        min-height: 0px !important;
        box-shadow: 0 0 0 1px transparent !important;

        &:hover {
            border: 0px !important;

        }
    }

    // .css-1rn7yzg-control {
    //     min-height: 0px !important
    // }
    // .css-1rn7yzg-control {
    //     min-height: auto;
    //     background: red;
    //     max-height: 20px !important;
    // }

    .css-1jqq78o-placeholder {
        font-family: "Poppins";
        font-size: 12px;
        line-height: 12px;
        color: #585656;
    }
}