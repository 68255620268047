@import "../../../../../style/_utilities.scss";
// .VirtualOfficeCads {
//     display: flex;
//     flex-direction: column;
//     column-gap: 15.63px;
//     max-height: 70vh;

.VirtualOfficeCads {
    .OfficeProperty-card {
        padding: 2px;
        overflow: hidden;
        width: 187px;
        box-shadow: 12.2879px 15.5962px 14.6415px -10.2651px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        background: $off-white;

        .select-property {
            top: 6px;
            left: 6px;
            accent-color: $dashboard-primary-theme-text;
        }

        .property-img {
            border-radius: 1.89044px;
            overflow: hidden;
            height: 65px;

        }

        .property-detail {
            padding: 3px;

            .property-detail-left {
                gap: 6px;

                .price {
                    font-weight: 500;
                    font-size: 14px;
                    color: $dashboard-primary-theme-text;
                }

                p {
                    font-size: 10px;
                    line-height: 9px;
                    color: $lite-black;
                }

                .bed-img {
                    width: 12.21px;
                    height: 9.41px;
                }

                .obr {
                    font-size: 9.45221px;
                    line-height: 9px;
                    color: #7B8191;
                }

                .card-divider {
                    background: #BBBBBB;
                    width: 1px;
                }

            }

            .property-detail-right {
                div {
                    height: 17px;
                    width: 17px;
                    border-radius: 50%;
                    background: $dashboard-primary-theme-text;
                }

                button {
                    font-size: 8px;
                    line-height: 7px;
                    color: $dashboard-primary-theme-text;
                    border-radius: 1.81915px;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        .card_btns {
            margin-top: 9.84px;

            button {
                border: 0.640394px solid #315efb;
                box-shadow: 8.32512px 10.5665px 9.9197px -6.95468px rgba(0, 0, 0, 0.2);
                font-weight: 500;
                font-size: 7.68473px;
                line-height: 8px;
                color: #315efb;
                width: 50%;
                padding: 5.44px 0px;
            }

        }

        .addNotBtn {
            font-style: normal;
            font-weight: 500;
            font-size: 7.68473px;
            line-height: 8px;
            padding: 5.85px 0px;
            background: #315EFB;
            border: 0.640394px solid #FFFFFF;
            box-shadow: 8.32512px 10.5665px 9.9197px -6.95468px rgba(0, 0, 0, 0.2);
            border-radius: 2.56158px;
            margin-top: 3.86px;
            color: #FFFFFF;
        }
    }
}

.dbr_text {
    font-weight: 400;
    font-size: 10.9688px;
    line-height: 11px;
    /* or 96% */

    color: #7b8191;
    padding-left: 6.77px;
}

// }

.select_all {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #585656;
    margin-left: 11px;
}

.send_tobtn {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 14px 40px;
}