.Paymentmethod_sectiON {
    padding: 77px;

    .card_img {
        // width: 280px;
        // height: 160px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    span {
        &.make_default {
            position: absolute;
            top: 8px;
            left: 8px;
            font-size: 10px;
            color: #fff;
            padding: 3px 9px;
            background: linear-gradient(0deg, #4eb152, #4eb152),
                linear-gradient(157.69deg,
                    rgba(255, 255, 255, 0.1) 6.02%,
                    rgba(255, 255, 255, 0) 95.12%);
            border: 0.5px solid #ffffff;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 4px;
        }
    }

    .btn_payment {
        width: 100%;
        border-radius: 3px;
        padding: 10px;
        color: #fff;

        &.delete {
            background: linear-gradient(0deg, #ff3838, #ff3838),
                linear-gradient(157.69deg,
                    rgba(255, 255, 255, 0.1) 6.02%,
                    rgba(255, 255, 255, 0) 95.12%);
            border: 1px solid #ffffff;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        }

        &.default {
            background: linear-gradient(0deg, #4eb152, #4eb152),
                linear-gradient(157.69deg,
                    rgba(255, 255, 255, 0.1) 6.02%,
                    rgba(255, 255, 255, 0) 95.12%);
            border: 1px solid #ffffff;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 0px 0px 2px 2px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .add_card {
        background: #FFFFFF;
        border: 1px solid #315EFB;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(20px);
        border-radius: 8px;
        display: flex;
        min-height: 170px;


        button {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;

            .add_symbol {
                width: 50px;
                height: 50px;
                background-color: #585656;
                font-size: 38px;
                font-weight: 400;
                color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                margin-top: 15px;
                font-size: 14px;
                color: #585656;
            }

            &:hover,
            &:focus {
                background: unset;
                box-shadow: none !important;
            }
        }
    }

    .Dgrid {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        column-gap: 24px;
        row-gap: 11.5px;
    }
}