@import '../../../../../../../style/utilities.scss';

.listing-Card {
    width: 190px;
    // margin: auto;

    .card-img {
        height: 222px;
        width: 190px;
        // height: 153px;
        border-radius: 14px;
        overflow: hidden;
        background: #43A4F6;

        img {
            height: 100%;
        }
    }

    p {
        font-weight: 600;
        font-size: 15.5839px;
        line-height: 17px;
        color: $homepage-text;
    }

    button {
        width: 129px;
        height: 35px;
        font-size: 12.1208px;
        line-height: 14px;
        color: $homepage-text;
        border: 1px solid #43A4F6;
    }
}

.arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid;
    top: 30%;
}

.left-arrow {
    left: -40px;
}

.right-arrow {
    right: -40px;
}


.slider-arrow {
    top: unset;
    bottom: 50%;
    // font-size: 15px !important;
    background: unset;
    border-radius: unset;
    position: absolute;
    z-index: 9;
    color: #000;
    cursor: pointer;

    &::after {
        content: "";
        background: red;
        width: 20px;
        height: 20px;
    }
}


.arrowleft {
    left: -25px;

}

.arrowright {
    right: -25px;
}