@import '../../../../../src/style/utilities';
.Mortgage_section_main {
    margin-bottom: 5px;
    width: 100%;
    height: 100%;

    .div_one {
        .div_one_child_one {
            width: 69%;

            // .range-wrap input[type=range] {
            //     width: 100%;
            //     border: 2px solid #585656 !important;
            //     height: 0px;
            //     // color: #585656 !important;
            //     accent-color: $dashboard-primary-theme-text;
            // }

            .mortgage_input {
                border-bottom: 0.676897px solid #546781;
                padding-bottom: 4.7px;
                margin-bottom: 25px;
                align-items: center;
                font-size: 12px;
                line-height: 12px;
                color: #585656;

                .input_name {
                    padding-right: 8.75px;
                    border-right: 0.676897px solid #546781;
                    width: 117px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    height: 100%;
                }

                input.text_size.border-0.pb-0 {
                    padding: 0.375rem 0.75rem;
                }

                .input-sec {
                    flex: 1;
                    padding-left: 15px;

                    input,
                    select {
                        width: 100%;
                        font-size: 12px;
                    }
                }
            }
        }

        .div_one_child_two {
            width: 31%;
        }
    }

    .div_two {
        border: 1px solid #315EFB;
        border-radius: 4px;

        .div_two_para {
            text-align: center;
            color: #315EFB;
        }

        .div_two_content {
            gap: 7px;
        }
    }

    .input_section_gap {
        gap: 30px;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;
        margin-bottom: 25px;
        width: 100%;
        font-size: 12px;
        line-height: 12px;
        color: #585656;


        .input_namepad {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 180px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-bottom: 3.4px;

            .red-star {
                color: red !important;
                font-size: 20px;
            }
        }

    }

    .range_section_start {
        .input_range_marginBottom {
            margin-bottom: 21px;

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
            }
        }

    }

    .buttons_two {
        .save_result {
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: #FFFFFF;
        }

        .save_btn {
            background: #315EFB;
            border: 2px solid #FFFFFF;
            box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(75px);
            border-radius: 4px;
            padding: 14px 47px;
        }

        .View_Full {
            padding: 14px 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            margin-left: 22px;
        }
    }
}

.mortage-result{
    p{
        color: $dashboard-primary-theme-text;
    }
    .monthlyPayment{
        border-top: 2px solid $dashboard-primary-theme-text;
    }
}