@import "../../../../../style/_utilities.scss";

// .container{
.filter_div {
  width: 100%;
  justify-content: space-between;

  .filter-search_a {
    width: 50%;
    height: fit-content;
    .filter-sec,
    .search-box,
    .search,
    .trans-input {
      z-index: 9999;
      max-width: 1000px;
    }
  }

  .button_Content {
    display: flex;
    justify-content: right;
    align-items: right;
    width: 50%;

    .btnn {
      width: 30%;

      .btn {
        border-radius: 3px;
        color: $dashboard-primary-theme-text;
        width: 100%;
        border: 2px solid $dashboard-primary-theme-text;
        border-radius: 20px;
        .UpFill {
          color: limegreen;
        }

        .DownFill {
          color: red;
        }
      }
    }

    .upfilbtn {
      margin-right: 10px;
    }
  }
}

.tContent {
  border: 1px solid $dashboard-primary-theme-text;
  border-radius: 4px;
  overflow: hidden;

  .document-table {
    width: 100%;

    tr {
      td {
        padding: 8px;
      }

      border-bottom: 1px solid $dashboard-primary-theme-text;
    }

    .tbody {
      .t_row {
        .d-flex {
          justify-content: space-between;

          .w-50 {
            margin-left: 2.5%;
          }

          .btnContent {
            justify-content: right;
            display: flex;

            .secondBContent {
              margin-right: 5%;
            }
          }
        }
      }
    }
  }
}
