@import "../../../style/_utilities.scss";

.CheckOut_agent_form {
    width: 100%;
    max-width: 1300px;
    margin: auto;
    // max-height: 85vh;
    // overflow-y: scroll;
    scroll-behavior: smooth;

    .left_section {
        .heding {
            padding-left: 30px;
        }

        h6 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $dashboard-primary-theme-text;
            margin-bottom: 20px !important;
        }

        .card .card-body {
            padding: 24px 36px 6px 30px;
        }

        .main_input {
            border-bottom: 0.676897px solid $homepage-text;
            padding-bottom: 4.7px;
            margin-bottom: 29px;

            .input_namepad {
                padding-right: 8.75px;
                border-right: 0.676897px solid $homepage-text;
                width: 144px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                padding-bottom: 3.4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: $lite-black;

                .red-star {
                    color: red !important;
                    font-size: 20px;
                }
            }

            .input-sec {
                width: 100%;

                .form-control:focus {
                    box-shadow: none;
                }

                input {
                    font-size: 12px;
                    line-height: 12px;
                    color: $lite-black;
                    border-radius: 0px;
                    background: transparent;
                }
            }
        }

        .payment_card {
            gap: 20px;
            margin-top: 41px;
            margin-bottom: 41px;

            .first_img {
                flex: 1;

                img {
                    border-radius: 8.36991px;
                }
            }

            .second_img {
                flex: 1;

                img {
                    border-radius: 8.36991px;
                }
            }

            .third_img {
                flex: 0.5;

                .add-car-img {
                    width: 50px;
                }
            }

            .add_new_cards {
                gap: px;

                p {
                    font-size: 14px;
                    line-height: 14px;
                    color: $lite-black;
                }

                .card_center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                }
            }

            .rccs {
                margin: 0px !important;
            }
        }

        .pay_now {
            button {
                font-weight: 500;
                font-size: 14px;
                line-height: 12px;
                padding: 14px 0px;
                background: linear-gradient(0deg, #f14d5d, #f14d5d),
                    linear-gradient(157.69deg,
                        rgba(255, 255, 255, 0.1) 6.02%,
                        rgba(255, 255, 255, 0) 95.12%);
                border: 2px solid #ffffff;
                box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
                border-radius: 2px;
                font-weight: 500;
                font-size: 14px;
                line-height: 12px;
                color: #FFFFFF;
                margin-bottom: 96px;
            }
        }
    }

    .right_section {
        h6 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $dashboard-primary-theme-text;
            margin-bottom: 20px !important;
        }

        .card .card-body {
            padding: 20px 24px 78px 24px;

            .border-bottom {
                border-bottom: 2px solid $dashboard-primary-theme-text !important;
            }

            .Commercial_text {
                // font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $lite-black;
                padding-bottom: 15px;
            }

            .Neighborhood_text {
                // font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $lite-black;
                padding-bottom: 20px;
            }

            .sub_total_text {
                p {
                    padding-top: 13px;
                    padding-bottom: 11px;
                    font-size: 16px;
                    line-height: 19px;
                    color: $dashboard-primary-theme-text;
                }
            }

            .discount_text {
                p {
                    // font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $lite-black;
                    padding-bottom: 13px;
                }
            }

            .total_text {
                p {
                    padding-top: 11px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $dashboard-primary-theme-text;
                    padding-bottom: 21px;
                }
            }

            .coupon_text {
                p {
                    // font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: $dashboard-primary-theme-text;
                }
            }

            .enter_code_input {
                display: table;

                .table_sell {
                    display: table-row;
                }

                .left {
                    display: table-cell;
                    padding-right: 7px;
                    width: 100%;


                    .form_control {
                        font-size: 12px;
                        line-height: 12px;
                        color: $lite-black;
                        padding: 10px;
                        width: 100%;

                    }
                }

                .right {
                    display: table-cell;
                    padding-left: 7px;
                    width: 100%;

                    .apply_btn {
                        font-size: 12px;
                        line-height: 12px;
                        padding-left: 18px;
                        padding-right: 18px;
                        padding-top: 14px;
                        padding-bottom: 14px;
                        background: $dashboard-primary-theme-text;
                        border: 2px solid #fdfdfd;
                        color: #fdfdfd !important;
                        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
                        backdrop-filter: blur(75px);
                        border-radius: 4px;
                    }

                }
            }
        }
    }



}

.forgot_modals {
    .card_img_end {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .MasterCard_img {
        height: 31px;
        width: 51px;
    }

    .CheckOutPage_body {
        padding: 0px 32px 30px 32px;


        .main_input {
            border-bottom: 0.676897px solid $homepage-text;
            padding-bottom: 4px;
            margin-bottom: 27px;

            .input_namepad {
                padding-right: 8.75px;
                border-right: 0.676897px solid $homepage-text;
                width: 162px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                font-size: 12px;
                line-height: 12px;
                color: $lite-black;
                padding-bottom: 3.5px;

                .red-star {
                    color: red !important;
                    font-size: 20px;
                }
            }

            .Expiry_Date_width {
                width: 198px !important;
            }

            .cvc_width {
                width: 70px !important;
            }

            .input-sec {
                width: 100%;

                .form-control:focus {
                    box-shadow: none;
                }

                input {
                    font-size: 12px;
                    line-height: 12px;
                    color: $lite-black;
                    border-radius: 0px;
                    background: transparent;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }
        }

        .gap_input {
            gap: 65px;

            .display_row {

                .display_cell {


                    .left {}

                    .right {}
                }
            }
        }

        .submit_btn {
            background: linear-gradient(0deg, #F14D5D, #F14D5D), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            border: 2px solid #FFFFFF;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 2px;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            /* identical to box height, or 86% */


            color: #FFFFFF;
            padding: 14px 0px;
        }




    }
}

.select-card {
    label {
        border-radius: 16px;
    }

    input[type=radio]:checked~label {
        // color: $dashboard-primary-theme-text !important;
        border: 2px solid $dashboard-primary-theme-text;
    }
}