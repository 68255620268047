.template {
  background-image: url(../../ImgAdv/MarketingHome.png);
  background-size: cover;
}

.form-container {
  width: 50%;

  form {
    h2 {
      font: 500 18/23px Poppins;
    }
    p {
      font: 400 14px/14px Poppins;

      a {
        font: 500 14px/14px Poppins;
      }
    }

    .input_div {
      border-bottom: 1px solid #546781;
      font: 400 14px/14px Poppins;
      color: #585656;

      label {
        border-right: 1px solid #546781;
        padding-top: 1%;
        padding-right: 2%;
        margin-bottom: 1%;
      }
    }

    .btn {
      font: 500 18px / Poppins;
      background: #43a4f6;
      border-radius: 20px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .form-container {
    width: 55%;
  }
}

@media screen and (max-width: 700px) {
  .form-container {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .form-container {
    width: 100%;
  }
}
