@import "../../../style/_utilities.scss";

.support_pagE {
  button.support_modal_btn {
    font-size: 12px;
    line-height: 12px;
    padding: 14px 63px;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: transparent;
    border-style: unset;
    border-width: 0;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
  }

  .table> :not(:first-child) {
    border-top: 0px solid currentColor !important;
  }

  tbody tr td .dropstart .dropdown-toggle::before {
    content: "";
    border-top: 0em solid transparent;
    border-right: 0em solid;
    border-bottom: 0em solid transparent;
  }

  li p {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .support_table {
    .upload_img {
      // height: 22px;
      width: 95px;
      height: 50px;
    }

    thead tr {
      font-size: 12px;
      line-height: 12px;
      color: #585656;
    }

    tbody tr {
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
      /* or 100% */

      color: #585656;

      td {
        vertical-align: middle;
      }
    }

    .table> :not(caption)>*>* {
      padding: 14px 14px;
    }

    table {
      margin-top: 15px;
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      thead tr th {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #585656;
        padding-bottom: 0px;
      }

      tbody tr {
        border: 1px solid #315efb;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
        border-radius: 4px !important;
        // margin-bottom: 15px;
        width: 99%;
        margin: auto;
        margin-bottom: 17px;

        .address_width {
          width: 100px;
          display: table-cell;
        }

        td {
          text-transform: capitalize;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #585656;

          .delete_droupdown {
            .dropdown-menu {
              min-width: 8rem !important;
            }

            .tool-aero::after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              /* right: 49px; */
              rotate: 134deg;
              transform: rotateX(38deg);
              transform: translateY(-556%);
              border-left: 1px solid $dashboard-primary-theme-text;
              border-top: 1px solid $dashboard-primary-theme-text;
              background: white;
              left: 83px;
              bottom: 46px;
            }

            ul {
              li {
                img {
                  height: 12px;
                  width: 12px;
                  margin-right: 8px;
                }

                p {
                  padding-top: 3.5px;
                  font-size: 12px;
                  line-height: 15px;
                  color: #000000;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }

      tbody {
        display: block;
        width: 100%;
        overflow: auto;
        max-height: 70vh;
        margin-top: 15px;
      }

      tr {
        display: flex;
        align-items: center;
      }

      td {
        flex: 1;
      }

      th {
        flex: 1;
      }
    }
  }
}

// modal-code

.supportmodal_header {
  padding: 27px 34px;

  p {
    font-size: 16px;
    line-height: 19px;
    color: $dashboard-primary-theme-text;
  }
}

.supportmodal_body {
  padding: 0px 34px 22px 34px;

  .main_input {
    border-bottom: 0.676897px solid #546781;
    padding-bottom: 4px;
    margin-bottom: 18px;

    .input_namepad {
      padding-right: 8.75px;
      border-right: 0.676897px solid #546781;
      width: 135px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      padding-bottom: 3.5px;

      .red-star {
        color: red !important;
        font-size: 20px;
      }
    }

    .input-sec {
      width: 100%;
      display: flex;

      .form-control:focus {
        box-shadow: none;
      }

      .image_on_input {
        position: absolute;
        top: -28px;
        left: 26px;

        p {
          font-size: 12px;
          line-height: 34px;
          color: #43a4f6;
        }
      }

      input,
      select {
        padding-top: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        border-radius: 0px;
        background: transparent;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .input_img_position {
        // position: absolute;
        // top: -2px;
        margin-left: 10px;

        img {
          padding-left: 26px;
        }

        p {
          font-size: 12px;
          line-height: 34px;
          color: #43a4f6;
        }
      }
    }
  }

  .submit_btn {
    padding: 14px 26px;
    font-size: 12px;
    line-height: 12px;
  }
}