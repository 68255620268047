@import "../../../../../style/_utilities.scss";



.PopupPropertyDetails {
    .contact_heade {
        padding: 28px 40px 21px 40px;

        // start card
        .forgot_title p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #315efb;

            img {
                height: 20px;
                width: 20px;
            }
        }
    }


    


}
.contact_modal_header{
    padding: 28px 40px 21px 40px;
    p{
        font-weight: 500;
font-size: 16px;
line-height: 19px;
color: $dashboard-primary-theme-text;
    }

}
.contact_body{
        padding: 0px 40px 36px 40px;
        .select_chakebox{
            padding-top: 36px;
            padding-bottom: 21px;

            .form-check-label{
                font-size: 12px;
                line-height: 12px;
                color: #585656;
            }
        }
    }
    .contact_footER {
        padding: 0px 40px 43px 40px;

        .Submit_btn {
            border-radius: 100px !important;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            padding: 0.875rem 7.875rem;

        }

        .cancel_btn {
            padding: 14px 127px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
        }
    }