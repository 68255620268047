@import "../../../style/utilities.scss";

.white-theme {
  background: #ffff;
}

.blue-theme {
  background-image: linear-gradient(to right, #cee4fc, #b9dafb, #b9dafb);
  // background-image: linear-gradient(to right, #CCE4FC, #B2D5FB, #B5D9FB);
}

.main-dashboard {
  height: 100vh;
  padding: 9px 16px;
  font-family: "Poppins";
  overflow: hidden;
  background: #ececec;

  .dashboard-inner {
    height: 100%;
    border: 3px solid rgba(49, 94, 251, 0.7);
    box-shadow: 0px 4px 200px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
    overflow: hidden;
    // height: 97vh;
    box-shadow: 1px -1px 0px rgba(49, 94, 251, 0.7) inset;
    // background: #ffff;
  }

  .dashboard-middle {
    gap: 10px;
    // gap: 20px;
    // padding-bottom: 23px;
    padding-right: 10px;
  }
}

.select-acc-type {
  .modal-dialog {
    max-width: 600px;
  }
}
