.signuppage_body {
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.signup_body {
  border: 5px #4568F1 solid; 
  display: flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 5px;
  gap: 10px;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.signup_body_right {
  width: 50%;
  background-color: #4568F1;
  border-radius: 25px;
  padding: 50px 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .signup_form{
    max-width: 450px;
  }
}

.signup_body_left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-logo{
    width: 100%;
    max-width: 500px;
  }
}



.signuppage_text {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.signuppage_text_head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #546781;
}

.signuppage_text_subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #9c9c9c;
}

.icon {
  font-size: 1.2rem;
  padding: 10px;
}

.signup_account_select{
  border-radius: 25px;
}

.signuppage_inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  .log-validation {
    align-self: flex-start;
  }

  .d-flex.flex-wrap.validation.pt-2 {
    align-self: flex-start;
    font-size: 14px;

    .d-flex.align-items-center.me-3.active {
      color: green;
    }

    .d-flex.align-items-center.me-3 svg {
      font-size: 16px;
    }
  }
}

.signuppage_disclaimer-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #546781;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 10px;
}

.signuppage_disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.signuppage_help_button {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  max-width: 485px;
  margin: auto;
  margin-bottom: 1rem;
}

.signuppage_forgot_password {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

button.signuppage_signin_btn {
  background: white;
  border-radius: 3.69889px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 7px 15px 7px 15px;
  border-radius: 25px;
}

.signuppage_signin_btn_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: black;
}

.signuppage_social_signup_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

button.signuppage_fb_signup,
.signuppage_google_signup {
  max-width: 485.6px;
  width: 100%;
  height: 49px;
  background: white;
  border-radius: 3.69889px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  padding-left: 15px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  text-align: center;
  color: black;
}

button.signuppage_google_signup {
  background: white;
  margin-top: 0.5rem;
  color: black;
}

.signuppage_fb_signup,
.signuppage_google_signup {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.signuppage_signup_help {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.signuppage_signup_link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #42a0f1;
}

.signuppage_signup_text {
  font-family: "Poppins";
  font-size: 12px;
  color: #000000;
}

// signup modal
.signupmodal {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 400px;
}

.signupmodal_container {
  width: 100%;
  max-height: auto;
  max-width: 989px;
  height: 370px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signupmodal_box1,
.signupmodal_box2,
.signupmodal_box3,
.signupmodal_box4,
.signupmodal_box5 {
  margin-top: -4rem;
  margin-left: 5px;
  margin-right: 5px;
  height: 232px;
  max-height: auto;
  max-width: 152px;
  width: 100%;
  background: linear-gradient(157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%);
  box-shadow: inset 0px 0px 25px 5px rgba(85, 85, 85, 0.1);
  border-radius: 4px;
  // transition: all 0.5s ease-in-out;

  &:hover {
    border: 2px solid #43A4F6;
    box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px #22222233;
  }
}

.userradio {
  position: absolute;
  z-index: -1;
}



.userradio:checked+.user-type {
  border: 2px solid #43A4F6 !important;
  box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px #22222233;
}


.signupmodal_box1_shape1,
.signupmodal_box2_shape1,
.signupmodal_box3_shape1,
.signupmodal_box4_shape1,
.signupmodal_box5_shape1 {
  width: 100%;
  max-width: 60px;
  height: 60px;
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 1rem;
  background: #43a4f6;
  box-shadow: inset 0px 1px 4px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.signupmodal_box1_subshape1,
.signupmodal_box2_subshape1,
.signupmodal_box3_subshape1,
.signupmodal_box4_subshape1,
.signupmodal_box5_subshape1 {
  width: 100%;
  max-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
  margin-left: -0.4rem;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.51) 100%);
  box-shadow: -1px 1px 6px rgba(168, 168, 168, 0.25),
    inset 0px 2px 4px rgba(190, 190, 190, 0.25);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
}

.signupmodal_box1_subshape_img {
  width: 30px;
  height: 30px;
  background: url("./image/skyscrapers\ 1.png");
}

.signupmodal_box2_subshape_img {
  width: 30px;
  height: 30px;
  background: url("./image/agent\ \(2\)\ 1.png");
}

.signupmodal_box3_subshape_img {
  width: 30px;
  height: 30px;
  background: url("./image//agent\ \(2\)\ 2.png");
}

.signupmodal_box4_subshape_img {
  width: 30px;
  height: 30px;
  background: url("./image/skyscrapers\ 1.png");
}

.signupmodal_box5_subshape_img {
  width: 30px;
  height: 30px;
  background: url("./image/skyscrapers\ 1\ \(1\).png");
}

.signupmodal_box1_text,
.signupmodal_box2_text,
.signupmodal_box3_text,
.signupmodal_box4_text,
.signupmodal_box5_text {
  width: 100%;
  max-width: 118px;
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin: auto;
  color: #2a52ac;

  p {
    color: #2a52ac;
    white-space: nowrap;

  }
}

.signupmodal_action_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4rem;
  max-width: 264px;
  width: 100%;
}

button.signupmodal_button {
  width: 264px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #43a4f6;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.33);
  border-radius: 2px;
}

.signupmodal_button_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #2a52ac;
}

.modal-header {
  border-bottom: 0;
}