.modal-body-property-detail {
    .p-text {
        color: #4E62B1;
    }

    .info-section {
        width: 100%;
        gap: 50px;

        .actions-btns {
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            box-shadow: -5.539999961853027px 7.03000020980835px 22.149999618530273px -11.738805770874023px rgba(34, 34, 34, 0.35);
            backdrop-filter: blur(0px);
            color: #4E62B1;
            font-family: Poppins;
            font-size: 11.573px;
            font-style: normal;
            font-weight: 400;
            line-height: 11.573px;
        }

        .like {
            background: transparent;
            border: 2px solid #2FC40B;
        }

        .notlike {
            background: transparent;
            border: 2px solid #4E62B1;
        }

        .add-note-section {
            margin-top: 35px;

            .form-control {
                color: #585656 !important;
                font-family: Poppins !important;
                font-size: 12px !important;
                font-style: normal;
                font-weight: 300;
                line-height: 14px;
            }

            .save-note-btn {
                // background:  linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
                border: 2px solid #4E62B1;
                box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
                border-radius: 4px;
                width: 127px;
                height: 40px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #4E62B1;

                &:hover {
                    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                    border-radius: 2px;
                    background: #4E62B1;

                }
            }

            .from-work {
                border-radius: 2px;
                box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
                backdrop-filter: blur(0px);
                padding: 10px;

                .content {
                    gap: 34px;

                    p {
                        color: #585656;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                    }
                }
            }

        }

        .location {
            .add-location {
                color: #4E62B1;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }

            .home-btn {
                padding: 10px 40px;
                border: none;
                border-radius: 2px;
                box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
                backdrop-filter: blur(0px);
                background: transparent;
            }

            .add-destination {
                color: #4E62B1;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
}