@import '../../../../style/_utilities.scss';
.Security_page_pad {
    padding: 34px;

    .pasword_sectext {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $dashboard-primary-theme-text;
    }

    .pasword_margine {
        margin-bottom: 30px;
    }

    .suspend_margine {
        margin-bottom: 23px;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;
        // margin-bottom: 26px;

        .input_namepad {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 160px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.5px;

            .red-star {
                color: red !important;
                font-size: 20px;
            }
        }

        .input-sec {
            width: 100%;

            .form-control:focus {
                box-shadow: none;
            }

            input {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
                border-radius: 0px;
                background: transparent;
            }
        }
    }

    .savebtn_text {
        height: 40px;
        width: 127px;
        margin-bottom: 58px;
    }

    .main_del_btn {
        .suspend_btn {
            padding: 12px 89px;
        }

        .Delete__btn {
            padding: 12px 106px;
        }

        img {
            height: 20px;
            width: 20px;
            margin-left: 8px;
        }
    }
}

.save__btn {
    background: #fdfdfd;
    border: 2px solid $dashboard-primary-theme-text;
    box-shadow: 13px 16.5px 15.49px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(75px);
    border-radius: 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $dashboard-primary-theme-text;

    &:hover {
        color: #fdfdfd;
        background: $dashboard-primary-theme-text;
        border: 2px solid #fdfdfd;
    }
}

.Delete__btn {
    background: linear-gradient(157.69deg,
            rgba(255, 255, 255, 0.1) 6.02%,
            rgba(255, 255, 255, 0) 95.12%);
    border: 1px solid #ff3838;
    box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ff3838;

    &:hover {
        border: 1px solid #fdfdfd;
        color: #fdfdfd;
        background-color: #ff3838;
    }
}

.suspend {
    .suspend-tooltip {
        width: 350px;
        border: 1px solid $dashboard-primary-theme-text;
        right: -26px;
        opacity: 0;
        box-shadow: 0px 0px 16px rgba(34, 34, 34, 0.35);
        top: 48px;
        flex-grow: 1;

        .tip {
            width: 10px;
            height: 10px;
            position: absolute;
            right: 10px;
            rotate: 313deg;
            transform: rotateX(45deg);
            transform: translateY(-83%);
            border-right: 1px solid $dashboard-primary-theme-text;
            border-top: 1px solid $dashboard-primary-theme-text;
            background: white;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 16px;
            color: #585656;
            padding: 8px 12px;
        }
    }


    &:hover {
        .suspend-tooltip {
            opacity: 1;
        }
    }
    
}
.delete_trip{
    .suspend-tooltip {
        width: 850px !important;
        border: 1px solid $dashboard-primary-theme-text;
        right: -26px;
        opacity: 0;
        box-shadow: 0px 0px 16px rgba(34, 34, 34, 0.35);
        top: 48px;
        flex-grow: 1;

        .tip {
            width: 10px;
            height: 10px;
            position: absolute;
            right: 10px;
            rotate: 313deg;
            transform: rotateX(45deg);
            transform: translateY(-83%);
            border-right: 1px solid $dashboard-primary-theme-text;
            border-top: 1px solid $dashboard-primary-theme-text;
            background: white;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 16px;
            color: #585656;
            padding: 8px 12px;
        }


        &:hover{
            opacity: 1;
        }
    }

    &:hover {
        .suspend-tooltip {
            opacity: 1;
        }
    }
}
// modal
.SecuRITY_modals {
    .modal-dialog{
        .modal-content{
            background: #FDFDFD;
            border: 1px solid $dashboard-primary-theme-text;
            box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
            backdrop-filter: blur(20px);
            border-radius: 4px;
        }
    }
}

.security_body {

    .form-control{
        border: 2px solid $dashboard-primary-theme-text;
        border-radius: 2px;
        background-color: transparent;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #585656;
    }
    .form-control:focus{
        box-shadow: none;
    }
    .forgot_title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $dashboard-primary-theme-text;

        img {
            height: 20px;
            width: 20px;
        }
    }

    .forgot_small_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #585656;

        span{
            color: $dashboard-primary-theme-text;
        }

        .red_texT{
            color: #FF3838 !important;
        }
    }
    // btn
    
    .suspend_btn{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        border: 2px solid $dashboard-primary-theme-text;
        background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        padding: 14px 8px;
        border-radius: 2px;
        color: $dashboard-primary-theme-text;

        &:hover{
            background:$dashboard-primary-theme-text;
            color: #FDFDFD;
            
            border-radius: 2px;
            border: 2px solid #FDFDFD;
        }
    }
    .submit_btn {
        background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        border: 2px solid  #FF3838;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 2px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color:  #FF3838;
        height: 40px;
        width: 127px;
        // padding: 14px 49px; 
        &:hover{
            color:  #FDFDFD;
            background:#FF3838;
            border: 2px solid  #FDFDFD;
        }
    }

    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background-image: url(./images/close.svg);
        cursor: pointer;
        border: 0;
        border-radius: 0.375rem;
        opacity: .5;

    }

}