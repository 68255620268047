.Neighborhood_Specialist_section {
  padding: 25px;

  .nav-tabs .nav-link {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    padding-bottom: 4px;
    text-transform: uppercase;
    color: #585656;
  }

  .nav-tabs {
    border: 0px;

    .nav-link {
      border: 0px;
    }
  }

  .nav-tabs .nav-link.active {
    outline: none;
    color: #315efb;
    border-bottom: 4px solid #315efb;
  }

  .select_towns {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #585656
  }
}