.Advertise5 {
    // height: 100vh;
    width: 100%;
    padding: 0% 5% 0%;
    position: relative;
    margin-bottom: 5%;

    .numberContent {
        align-items: center;
        gap: 3%;
    }

    .Estimate-text {
        .AdvEle_text {
            width: 30%;
        }
    }

    .text-left {
        margin-left: 7%;
    }

    .Img_Content2 {

        .ElevatedImg {
            max-width: 700px;
            max-height: 600px;
            margin-left: 7%;
        }

        .AdvIMG {
            display: block;
            justify-content: center;
            max-height: 750px;
            margin: 0% 2% 0%;
            .ElevatedImgF {
                max-height: 300px;
                max-width: 700px;
            }

            .ElevatedImgS {
                max-height: 400px;
                max-width: 700px;
                margin-top: 5%;
            }
        }
    }

    .EleDowtImg {
        width: 22%;
        transform: translateX(-18%);
        right: 69%;
        top: 50%;
        z-index: -1;
    }
}
// ----------------------------------------------------------
@media screen and (min-width: 360px) and (max-width:1000px) {
    .Advertise5 {
        padding: 0% 0% 0%;
        position: unset;

        .numberContent {
            align-items: center;
            gap: 10%;
        }

        .Estimate-text {
            .AdvEle_text {
                width: auto;
            }
        }

        .number2Img {
            max-width: 50px;
        }

        .Img_Content2 {
            display: block;
            justify-content: center;
            flex-direction: column;
        }

        .Img_Content2 .ElevatedImg {
            width: 100%;
            display: block;
            margin-left: 0%;
        }

        .Img_Content2 .AdvIMG .ElevatedImgF {
            width: 100%;
            margin-top: 5%;
        }

        .Img_Content2 .AdvIMG .ElevatedImgS {
            width: 100%;
            margin-top: 5%;
        }

        .Advertise5 .text-left {
            margin-left: 0%;
        }

        .EleDowtImg {
            display: none;
        }

    }
}