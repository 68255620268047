.terms_heading {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  font-weight: bold;
  margin-left: 5rem;
  margin-top: 1rem;
  position: relative;
  top: -7rem;
  z-index: 5;
}

.term-privacy-container {
  font-family: Poppins;

  .skyblue-bottom-line {
    height: 5px;
    background: rgb(66, 165, 245);
    bottom: 0px;
  }

  .orange-bottom-line {
    bottom: 5px;
    height: 5px;
    background: rgb(242, 197, 54);
  }
}

.terms-header {
  min-height: 40vh;
  border-bottom: 5px solid rgb(0, 21, 128);
}

.terms-heading {
  bottom: 15px;
  left: 3%;
}

.home-link-logo {
  left: 46%;
  top: 10px;
}

// .terms_container {
//   margin-left: 5rem;
//   // margin-bottom: 1rem;
//   // margin-top: 1rem;
//   padding-bottom: 30px;
//   margin-right: 5rem;
// }

.terms_paragraph {
  color: black;
  // font-size: 1.1rem;
}

a {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.terms_image {
  width: 100%;
  margin: 0px;
  // background-size: auto;
  // height: 20rem;
  // border: 2px solid green;
  // background-image: url("../../images//Toc&PP/tocimage.svg");
}

// https://images.squarespace-cdn.com/content/v1/58615e0d9f7456471a537406/1482776288791-CR6Z9BCVLWQQXH9O0UCU/Banner-Buildings-1.png?format=2500w
.aficado_url {
  position: absolute;
  top: 1rem;
  left: 35rem;
}