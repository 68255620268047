@import "../../../../../style/_utilities.scss";

.edit-contact {
  gap: 32px;

  .title {
    color: $dashboard-primary-theme-text;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
  }

  .edit-form {
    padding: 45px;
    border: 1px solid $dashboard-primary-theme-text;
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    gap: 32px;
    max-height: 65vh;
    overflow-y: scroll;

    .input-box {
      width: 48.5%;

      p {
        font-size: 12px;
        line-height: 12px;
        color: $lite-black;
      }
      .upload-doc {
        border: 1px solid #315efb;
        border-radius: 3px;
        padding: 3px;
        position: relative;
        svg {
          color: $dashboard-primary-theme-text;
          font-size: 30px;
        }
      }
      .view-doc {
        border: 1px solid #315efb;
        border-radius: 3px;
        padding: 3px;
        position: relative;
        svg {
          color: $dashboard-primary-theme-text;
          font-size: 30px;
        }
      }
      .view-doc::after {
        content: "↓";
        position: absolute;
        size: 10px;
        top: 0px;
        right: 2px;
        color: #315efb;
        font-size: 9px;
      }
    }

    .blue__btn {
      padding: 14px 60px;
    }
  }
}

// custum input bar styling
.custume-inputbar {
  border-bottom: 0.676897px solid $lite-black;

  .input-label {
    border-right: 0.676897px solid $lite-black;
    // width: 30%;
    width: 165px;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $lite-black;
    }
  }

  input,
  textarea {
    font-size: 12px;
    line-height: 12px;
    color: $lite-black;
  }
}

//office map view property
.office-mapView {
  gap: 32px;
  max-height: 80vh;
  height: -webkit-fill-available;
  overflow: hidden;
  // background: red;

  .grid-two {
    display: grid;
    grid-template-columns: 2fr 10fr;

    .left_side {
      max-height: 70vh;
    }

    .right_side {
      max-height: 70vh;
      overflow: hidden;
    }
  }
}

.search {
  max-width: 426px;
  border: 2px solid $dashboard-primary-theme-text;
  box-shadow: $box-shadow;
  backdrop-filter: blur(75px);
  border-radius: 4px;
  padding: 3px 19px;
  background: $off-white;
}

.search-icon {
  background: $off-white;
}

.filter-icon {
  width: 40px;
  height: 40px;
  margin-left: 30px;
  box-shadow: $box-shadow;
  background: $off-white;
  border: 2px solid $dashboard-primary-theme-text;
  border-radius: 4px;

  img {
    width: auto;
  }
}

.MuiAutocomplete-popper {
  z-index: 100000;
}
