@import '../../../../style/utilities.scss';

.filter-card-result {
   gap: 19px;
   max-height: 75vh;
   padding-inline-end: 10px !important;
}

.filter-card-toggle {

   top: 72px;
   left: 15px;
   width: 30px;
   height: 30px;
   z-index: 1001;
   border-radius: 2px;
   // border: 3px solid #FFFFFF;
   background: #FFFFFF;
   box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
   // background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);

}

.filter-map-result {
   transition: all 5s ease-in-out;
}

.filter-result-grid {
   .grid-left {
      grid-area: left;
   }

   .grid-right {
      grid-area: right;
   }

   display: grid;
   grid-template-areas:'left right right right right right right ';
   gap: 10px;
   background-color: #2196F3;
   padding: 10px;
}