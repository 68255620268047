@import "../../../../../../style/_utilities.scss";

.property-feature {
  .MustSee {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: $dashboard-primary-theme-text;
  }

  .heading {
    font-size: 14px;
    color: $dashboard-primary-theme-text;
    margin-bottom: 15px !important;
  }

  .sub-heading {
    color: $dashboard-primary-theme-text;
    font-weight: normal;
  }

  .feature {
    display: flex;
    justify-content: space-between;
  }

  .aminity-container {
    .aminity {
      min-width: 94px;

      svg {
        color: $dashboard-primary-theme-text;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }

  .custom-table {
    border-collapse: collapse;

    th,
    td {
      border: none;
      padding: 8px;
    }

    .small-col {
      width: 80px;
      vertical-align: sub;
    }

    .large-col {
      // width: 200px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;

      p {
        width: fit-content;
        display: inline-block;
        padding-right: 10px;
        margin: auto;
      }
    }
  }
}

.add-location > * {
  margin: 15px 0;
}

.car_group > * {
  margin-left: 10px;
}
