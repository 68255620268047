@import "../../../style/_utilities.scss";

.saved_saerch_main {
  width: 100%;
}

.saved_search_proprty_action_btn {
  margin-top: 1rem;
  margin-left: 1rem;
}

.saved_search_section {
  .save_serch_text {
    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $dashboard-primary-theme-text;
      padding-bottom: 26px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      color: #585656;
      padding-bottom: 13px;
    }
  }

  .listing__card {
    .activeSearch {
      background: linear-gradient(90deg, rgba(57, 100, 252, 0.7232142857142857) 0%, rgba(49, 94, 251, 0.7596288515406162) 38%, rgba(50, 39, 39, 0) 100%);
      border: 0.94375px solid $dashboard-primary-theme-text;
      color: #fff;

      p {
        font-weight: 700;
      }
    }
  }


  .listing__card .card {
    margin-bottom: 20px;

    .card-body {
      padding: 14px;

      .card_style {
        p {
          text-transform: capitalize;
          font-weight: 300;
          font-size: 12px;
          line-height: 12px;
          // color: #585656;
        }
      }

      .tool_target {
        .tool-aero::after {
          left: 130px;
          bottom: 45px;
        }
      }

      .delete_droupdown {
        .dropdown-menu {
          min-width: 11rem !important;
        }

        ul {
          li {
            img {
              height: 12px;
              width: 12px;
              margin-right: 8px;
            }

            p {
              padding-top: 3.5px;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
              vertical-align: middle;
            }
          }
        }
      }

      .dropstart .dropdown-toggle::before {
        content: "";
        border-top: 0em solid transparent;
        border-right: 0em solid;
        border-bottom: 0em solid transparent;
      }
    }
  }

  .right_card .card_border {
    padding: 20px 26px 20px 26px;

    .top_text {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      color: $dashboard-primary-theme-text;
      margin-bottom: 13px !important;
    }

    .card_space_between {
      display: flex;
      column-gap: 20px;
      row-gap: 15px;
      min-height: 45vh !important;
      max-height: 45vh;
      overflow-y: scroll;
      flex-wrap: wrap;
      min-height: 150px;
    }

    .View_All_btn {
      margin-top: 20px;
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 12px;
      line-height: 18px;
    }
  }


}