.main_ADS_conatiner {
    flex: 1;

    .btn_content {
        text-align: end;

        .new_btn {
            width: 15%;
            border: 3px solid blue;

        }
    }

    .table_heading {
        margin-top: 5%;
        thead {
            tr {
                border-bottom: hidden;

                th {
                    color: #315EFB;
                    min-width: 120px;
                    align-items: start;
                    text-align: start;
                }
            }

        }
    }

    .ads_table-container {
        max-width: 1700px;
        padding-right: calc(var(--bs-gutter-x) * .0);
        padding-left: calc(var(--bs-gutter-x) * .0);
        border-radius: 10px;
        border: 2px solid #585656;
        overflow-x: hidden;

        table {

            // position: relative;
            // top: -40px;
            // margin-bottom: -40px;
            thead {
                display: none;

                tr {
                    display: none;

                    th {
                        display: none;
                    }
                }

                tbody {
                    tr {
                        border-bottom: 2px solid #585656;

                        // padding: 10% 0% 10%;
                        td {
                            padding: 1% 0% 1%;

                            .span {
                                width: 84px;
                                height: 16px;
                                border-radius: 2px;
                                color: #ffffff;
                                font-family: Poppins;
                                line-height: 10px;
                                font-size: 8px;
                                font-weight: 300;
                                align-items: center;
                            }
                            .span1{
                                width: 43px;
                                
                            }
                            .span2{
                                width: 35px;
                            }
                           .badge{ 
                            font-size: .50em;
                        --bs-badge-font-weight: 0;
                        }
                            .dowtLogo{
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }

                }
            }
        }
    }
}

