@import '../../../style/utilities.scss';

.open-listing-section {
    .open-listing-heading {
        font-size: 16px;
        line-height: 14px;
        color: $repeated-text-color;
    }

    .open-listing-map {
        height: 260px;
    }

    .listing-middle-section {
        overflow-y: scroll;
        scroll-behavior: smooth;
        max-height: 75vh;
    }

    .open-listing-left-section {
        min-width: 200px;
    }
}

.open-listing-search-box {
    max-width: 100% !important;
    width: 100%;

    .search {
        max-width: 95% !important;
    }
}