.advert {
    background: linear-gradient(180deg, #DBEEFD 90%, transparent 70%);
    padding-top: 2%;
    .Adv_pop_content {
        width: 80%;
        margin: 0 auto;
        border-radius: 10px;
        padding-top: 3%;
        box-shadow: rgba(89, 217, 240, 0.35) 0px 5px 15px;

        .btn_content {
            width: 100%;
            padding: 3% 0% 3%;
            display: flex;
            justify-content: end;
            align-items: end;
            text-align: end;

            .Typical_Heading {
                margin: 0% 5% 0%;

            }

            .app-blue-btn {
                background-color: #43A4F6;
                font: 400 16px/1.2 Poppins;
                margin-left: 3%;
                margin-right: 14%;
            }
        }
        .pop_design_content {
            .d-flex {
                border-top: 1px solid #000000;
                border-bottom: 1px solid #000000;
                font: 400 14px/18px Poppins;
                .typical_p1 {
                    width: 25%;
                }

                .typical_p {
                    width: 50%;
                }
            }
            .exp{
                .type_exp_heading {
                    padding-left: 25%;
                }
            }

            
        }


    }

    .Adv_pop_card {
        font: 14px/18px Poppins;
        background-color: #43A4F6;
        max-width: 360px;
        border-radius: 10px;

        .pop_design_card {
            min-height: 350px;
            margin-bottom: 10%;
        }
    }
}

@media screen and (min-width: 300px) and (max-width:1000px) {
    .advert {
        height: auto;
        justify-content: center;
        display: block;
        flex-wrap: wrap;
        .Adv_pop_content {
            width: 100%;
            .demo{
                flex-wrap: wrap;
            .pop_design_content{
                .d-flex {
                    font-size: 14px;
                    justify-content: space-around;
                    display: flex;
                }
            }
        }
        .btn_content{
            display: block;
            align-items: center;
            text-align: center;
            justify-content: center;
            .app-blue-btn{
                margin-top: 5%;
            }
        }
     }
    }
}