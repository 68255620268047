@import '../../../../style/_utilities.scss';
.typeof_account_pag{
    padding: 34px;

    .Account_text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $dashboard-primary-theme-text;
        margin-bottom: 44px;
    }

    

    .radio_button{
        margin-bottom: 75px;
        .type_of_account{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        padding-right: 53px;
        padding-top: 3px;
        

        .form-check{
            display: inline-block;
        }
    }

        .resdent_text{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #6A696A;
            padding-top: 3px;
        }
    }
}