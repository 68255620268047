.map-view-container {
  // max-height: 260px;
  height: 75vh;
  overflow: hidden;

  .leaflet-popup-content {
    margin: 0;
  }
  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;
  }

  .leaflet-container a.leaflet-popup-close-button {
    top: -1px;
    right: -4px;
    margin-top: 10px;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    padding: 0.25em 0.25em;
    color: #000;
    background-image: url(./Image/close.svg);
    cursor: pointer;
    opacity: 0.5;
  }

  .leaflet-container a.leaflet-popup-close-button > span {
    display: none;
  }

  .leaflet-bar {
    border: 0px;
  }

  .leaflet-marker-icon {
    object-fit: contain;
  }
  .leaflet-top {
    z-index: 400;
  }
  .leaflet-top .leaflet-left {
    z-index: 400;
  }
}
.leaflet-top .leaflet-left {
  z-index: 400;
}
