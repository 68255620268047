@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

$dashboard-primary-theme: linear-gradient(
  108.02deg,
  rgba(255, 255, 255, 0.4) -6.48%,
  rgba(255, 255, 255, 0.1) 106.42%
);
$dashboard-primary-theme-text: #315efb;
$sidebar-primary: #315efbd9;
$lite-black: #585656;
$white: #ffffff;
$off-white: #fdfdfd;
$home-primary: #43a4f6;
$repeated-text-color: #4e62b1;

$homepage-text: #546781;

$layout-size: 1366px;
$box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

.section {
  padding: 0 56px;
}

.pointer {
  cursor: pointer;
}
.pri-text-clr {
  color: $dashboard-primary-theme-text;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-shodow {
  box-shadow: $box-shadow;
  border: 2px solid $dashboard-primary-theme-text;
  border-radius: 20px;
}

.blure-shadow {
  box-shadow: 12.2879px 15.5962px 14.6415px -10.2651px rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(18.9044px);
}

input,
textarea,
select {
  background: transparent;
  border: 0 !important;
  color: inherit;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.layout {
  max-width: 1266px;
  margin: auto;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scroll-y {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

// .layout{
//     max-width: $layout-size;
//     margin: auto;
// }

.blue__btn {
  background: #fdfdfd;
  border: 2px solid #315efb;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 20px;
  color: #315efb;

  &:hover {
    background: #315efb;
    border: 2px solid #fdfdfd;
    color: #fdfdfd !important;
  }
}

.red__btn {
  background: #fdfdfd;
  border: 2px solid #ff3838;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 20px;
  color: #ff3838;

  &:hover {
    background: #ff3838;
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
  }
}
.green__btn {
  background: #fdfdfd;
  border: 2px solid #1fe11c;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 20px;
  color: #1fe11c;

  &:hover {
    background: #1fe11c;
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
  }
}

.card_border {
  background: linear-gradient(
    108.83deg,
    rgba(255, 255, 255, 0.4) -9.92%,
    rgba(255, 255, 255, 0.1) 115.16%
  );
  border: 1px solid #9fccfa;
  box-shadow: 5px 5px 15px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.gray__btn {
  background: linear-gradient(
    157.69deg,
    rgba(255, 255, 255, 0.1) 6.02%,
    rgba(255, 255, 255, 0) 95.12%
  );
  border: 2px solid #686868;
  box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
  border-radius: 100px;
  color: #686868;

  &:hover {
    color: #fdfdfd;
    border: 2px solid #fdfdfd;
    background-color: #686868;
  }
}

.white__btn {
  background: #f7f9fb;
  border: 2px solid #ffffff;
  color: $lite-black;
  &:hover {
    color: #f7f9fb;
    border: 2px solid #fdfdfd;
    background-color: $lite-black;
  }
}

.blue__btn {
  background: #fdfdfd;
  border: 2px solid #315efb;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    background: #315efb;
    color: #fdfdfd;
    border: 2px solid #fdfdfd;
  }
}

.input_border {
  background: #fdfdfd;
  border: 2px solid #315efb;
  box-shadow: 13px 16.5px 15.49px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 4px;
}

.modal-dialog {
  .modal-content {
    border: 1px solid $dashboard-primary-theme-text;
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    background: #ffffff !important;
    // border-radius: 4px;
  }
}

.form-check-input {
  border: 1.5px solid $dashboard-primary-theme-text !important;
}

.scrollS {
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-right: 5px;
  // padding-inline-end: 24px;

  &::-webkit-scrollbar {
    width: 6.62px;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 94.5483px;
    position: relative;
  }

  &::-webkit-scrollbar-thumb {
    width: 4.73px;
    background: #315efb;
    border-radius: 94.5483px;
  }
}

.homepage-scroll {
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  &.proximity {
    scroll-snap-type: y proximity;
  }
  .span-to-top {
    scroll-snap-align: start;
  }
}

.tool_border {
  background: #ffffff;
  border: 1px solid $dashboard-primary-theme-text;
  border-radius: 0% !important;
}

.tool-aero::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  /* right: 49px; */
  rotate: 134deg;
  transform: rotateX(38deg);
  transform: translateY(-556%);
  border-left: 1px solid $dashboard-primary-theme-text;
  border-top: 1px solid $dashboard-primary-theme-text;
  background: white;
  left: 83px;
  bottom: 46px;
}
