.d-flex{
  .rd_btn{
    background: #fdfdfd;
  border: 2px solid #ff3838;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #ff3838;
  }
  .rd_btn:hover {
    background: #ff3838;
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
  }
.grn_btn{
  background: #fdfdfd;
  border: 2px solid #1fe11c;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #1fe11c;
}
.grn_btn:hover {
  background: #1fe11c;
  border: 2px solid #fdfdfd;
  color: #fdfdfd;
}
}