.MainCard_container {
    display: block;
    justify-content: left;

    .card_container {
        align-items: center;
        max-height: 68vh;
        overflow-y: scroll;
        gap: 40px;
        .card_border {
            // max-width: 215px;
            width: 187px;
            height: 207px;

            .text-center {
                margin-right: calc(-.0 * var(--bs-gutter-x));
                margin-left: calc(-.0 * var(--bs-gutter-x));

                img {
                    border-radius: 10px;
                }
            }

            .text_content {
                .d-flex {
                    justify-content: flex-start;
                    font-size: 13px;

                    .label{
                        width: 30%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .info{
                        width: 65%;
                    }
                }
            }
        }
    }
}

// style={{justifyContent:"flex-start", fontSize:"13px"}}


// margin-right: calc(-.5 * var(--bs-gutter-x));
//     margin-left: calc(-.5 * var(--bs-gutter-x));