@import "../../../style/utilities.scss";

.marketing-card-container {
  row-gap: 28px;
  column-gap: 10px;
  height: fit-content;
  margin-top: 5%;

  .marketing-card {
    width: 164px;
    height: 202px;

    .marketing-logo {
      height: 60px;
      width: 60px;

      img {
        object-fit: fill;
      }
    }

    .marketing-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $repeated-text-color;
    }
  }
}

.marketing-page-popup {
  button {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $dashboard-primary-theme-text;
    border: 1px solid $dashboard-primary-theme-text;
    text-transform: capitalize !important;

    &:hover {
      color: $white;
    }
  }
}

.boost-marketing-modal {
  .modal-content {
    max-width: 625px;
    margin: auto;
  }

  .boost-marketing-modal-body {
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: $lite-black;
    }
  }
}

.boost-market-button {
  border-radius: 20px;
}
