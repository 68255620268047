@import "../../../../../style/_utilities.scss";


.CorporateInformation_sec {
    padding: 17px 50px;

    .profile_ptext {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $dashboard-primary-theme-text;
    }

    .profile_pic {
        width: 80px;
        border-radius: 50%;
        aspect-ratio: 1/1;
    }

    .uplada_btn {
        background: #FDFDFD;
        border: 2px solid #315EFB;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(75px);
        border-radius: 4px;
        font-family: 'Poppins';
        font-size: 12px;
        line-height: 16px;
        color: #315EFB;
        height: 40px;
        width: 204px;
        background-color: transparent;
        padding-left: 10px;
        margin-bottom: 1.375rem;

        .upload_img {
            width: 10px;
            width: 10px;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $dashboard-primary-theme-text;
        padding-top: 1rem;
        margin-bottom: 1rem !important;
    }

    .world_text {
        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-transform: capitalize;
            color: #585656;


        }

        .form-check-labels {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 10px;
            color: #315EFB;
        }
    }

    .last_name_width .input_name {
        width: 137px !important;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;
        margin-bottom: 1.1875rem;

        .input_name {
            padding-right: 17.75px;
            border-right: 0.676897px solid #546781;
            width: 150px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.4px;

            .red-star {
                color: red;
            }
        }

        .input-sec {
            width: 100%;
            display: flex;
        }

        .form-control {
            padding-left: 17.75px;
        }

        .form-control:focus {
            box-shadow: none;
        }

        input {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            border-radius: 0px;
            background: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

    }

    .save_btN {
        background: #FDFDFD;
        border: 2px solid $dashboard-primary-theme-text;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(75px);
        border-radius: 4px;
        height: 40px;
        width: 127px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;

        &:hover {
            background: $dashboard-primary-theme-text;
            color: #FDFDFD;
            border: 2px solid #FDFDFD;

        }
    }
}