// .reviewcards_data {
//   display: flex;
//   gap: 35px;
// }
// .reviewcards_side_container {
//   height: 545px;

//   width: 100%;
//   max-width: 451px;
//   background:
// //   url(360_F_237711475_9ht6fsO3yhDKpB49g1QOHqCAaYsHvJHu.jpg),
//     linear-gradient(
//     157.69deg,
//     rgba(255, 255, 255, 0.1) 6.02%,
//     rgba(255, 255, 255, 0) 95.12%
//   );
//   box-shadow: 13px 16.5px 15.49px -10.86px rgba(34, 34, 34, 0.2),
//     inset 0px 0px 25px 5px rgba(85, 85, 85, 0.1);
//   border-radius: 10px;
// }
// .reviewcards_container {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
// }
// .reviewcards_side_container_data {
//   margin: 1rem;
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }

// sawan code

@import "../../../../style/_utilities.scss";

.main_reviewED_section {
  width: 100%;

  .hide-scrollbar {
    max-height: 85vh;
    overflow-y: scroll;
    padding-bottom: 10px;
    padding-left: 15px;
  }

  .card_padinG {
    padding: 22px 25px 22px 25px;
  }

  .left-sec {
    gap: 35px;
    padding: 20px;
  }
}

.forgot_modals {
  .modal-dialog {
    .modal-content {
      background: #fdfdfd;
      border: 1px solid #315efb;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(20px);
      border-radius: 4px;
    }
  }
}

.Review_Warren_modals {
  .rating {
    svg {
      font-size: 21px !important;
    }
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #585656;
  }

  .form-check-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #585656;
  }

  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #585656;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: #585656;
  }

  .star_orange svg {
    color: #ffcf3d;
    font-size: 14px;
  }

  .How_likely {
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;

      span {
        color: #4e62b1 !important;
      }
    }
  }

  .forgot_title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #315efb;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .forgot_small_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #585656;
  }

  .main_input {
    border-bottom: 0.676897px solid #546781;
    padding-bottom: 4.7px;
    margin-bottom: 21px;

    .input_name {
      padding-right: 17.75px;
      border-right: 0.676897px solid #546781;
      width: 195px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      padding-bottom: 3.4px;

      .red-star {
        color: red;
      }
    }

    .form-control {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
    }

    .height_zero {
      height: 10px;
    }

    .input_widthonly {
      width: 214px !important;
      border-right: 1px solid black !important;
      height: 15px;
    }

    .input-sec {
      width: 100%;
    }

    .form-control {
      padding-left: 17.75px;
    }

    .form-control:focus {
      box-shadow: none;
    }

    input {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      border-radius: 0px;
      background: transparent;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .submit_btn {
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #315efb;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #315efb;
    height: 40px;
    width: 127px;
    // padding: 14px 49px;

    &:hover {
      box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
      color: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 2px;
      background: #315efb;
    }
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #585656;
    text-decoration: none;

    span {
      color: #315efb;
    }
  }

  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background-image: url(./images/close.svg);
    cursor: pointer;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
}

.right-sec {
  &:first-child {
    margin-top: 10px;
  }
  .review-group {
    margin-top: 20px;
    box-shadow: 0px 0px 25px 5px #5555551a inset;
  }
  min-height: 750px;
  border: 1px solid;
  border-radius: 10px;
  border-image-source: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 217.5%
  );
  box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px #22222233;

  box-shadow: 0px 0px 25px 5px #5555551a inset;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 217.5%),
    linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
}
