.Advertise4 {
  width: 100%;
  position: relative;
  padding: 0% 5% 0%;
  .advNumberImg {
    align-items: start;
    gap: 3%;
  }
  .EstimateHeading {
    font-weight: 600;
    font-size: 40px;
  }

  .Estimate-text {
    height: 15%;
    padding-left: 7.5%;

    .p-text {
      width: 25%;

    }
  }

  .EstimateImg {
    z-index: 1;
    max-width: 1200px;
    max-height: 700px;
  }
}

.Advertise4 .dowtImg2 {
  transform: translateX(-18%);
  height: 60%;
  margin-top: 26%;
  margin-left: 25%;
}

// ---------------------------------------------------
.pop-design {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  background: #fff;
  z-index: 11;
  bottom: 33%;
  left: 60%;
  width: clamp(22%, 40%, 200px);

  .estimateB_heading {
    padding: 18% 20% 5%;
    text-align: center;
    
  }

  .Renova_heading {
    padding: 10% 20% 15%;
  }

  .pop_design_content {
    width: 80%;
    margin-left: 40px;
    .d-flex{
      border-bottom: 1px solid #000000;
      padding: 4% 0% 5%;
      p{
    color: #585656;

      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width:1000px) {
  .Advertise4 {
    position: unset;
    padding: 2% 2% 2%;

    .EstimateHeading {
      font-weight: 600;
      font-size: 25px;
    }
  }

  

  .Advertise4 .advNumberImg {
    max-height: 50px;
    align-items: center;
    gap: 10%;
    margin-top: 5%;

  }

  .Advertise4 .Estimate-text {
    height: 15%;
    padding-left: 0px;
    margin-top: 5%;

    .p-text {
      width: auto;
    }
  }

  .headingContent {
    .number1Img {
      max-height: 50px;
      max-width: 1000px;
    }
  }

  .Advertise4 .getHeading {
    font-size: 18px;

  }

  .Advertise4 .EstimateImg {
    width: 100%;
  }

  .Advertise4 .dowtImg2 {
    display: none;
  }

  // -------------------popup-----------------------------
  .pop-design {
    position: unset;
    width: 100%;
    margin-top: 5%;

    .estimateB_heading,
    .Renova_heading {
      padding: 10% 5% 10%;
      font-size: 18px;
      text-align: center;
    }

    .pop_design_content {
      width: 80%;
      margin-left: 35px;
      color: #585656;

      hr {
        height: 2px;
        font-weight: 800;
      }
    }
  }
}