@import "../../../../../style/utilities.scss";

.share__modal {
}

.blue__btn {
  background: #fdfdfd;
  border: 2px solid #315efb;
  box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 20px;
  color: #315efb;
  min-width: 64px;
  font-size: 0.8rem;
  padding: 10px 20px 10px 20px;
  &:hover {
    background: #315efb;
    border: 2px solid #fdfdfd;
    color: #fdfdfd !important;
  }
}

.name__text {
}
.input__box {
  border-width: 1px !important;
  .input__box__name {
    height: 23px !important;
    margin-left: 37px;
    border-right-width: 1px !important;
  }
}
.input__box__doc {
  margin-top: 15px !important;
  border-width: 1px;
}

.multi__select__body {
  width: 100% !important;
  .MuiOutlinedInput-root {
    .MuiSelect-select {
      padding: 0px !important;
      border-color: white !important;
      .MuiStack-root {
        .MuiChip-root {
          background-color: #315efb !important ;
          color: white !important;
          border-radius: 4px !important;
          svg {
            color: white;
          }
        }
      }
    }
    .MuiSelect-icon {
      display: none;
    }
    fieldset {
      border: none;
    }
  }
}
