@import "../../../../../style/utilities.scss";

.dashboard-filter-popup {
  color: $lite-black;
  font-size: 12px;
  line-height: 12px;

  .modal-content {
    max-width: 730px;
  }

  .filter-row {
    display: flex;
    flex-direction: column;
    gap: 11px;

    .update-unit {
      height: 40px;
      width: 140px;

      input {
        color: inherit;
        width: 80%;
      }

      svg {
        font-size: 15px;
      }
    }

    .Amenities {
      row-gap: 6px;
      column-gap: 9px;

      .aminity {
        padding: 5px 14px;
        background: #ffffff;
        border: 1px solid $dashboard-primary-theme-text;
        border-radius: 120.57px;
      }

      .select-aminity {
        .aminity-input {
          position: absolute;
          z-index: -1;
        }

        input[type="checkbox"]:checked + label {
          background: $dashboard-primary-theme-text;
          color: $white;
          border: 1px solid $white;
        }
      }
    }
  }

  .filter-radio {
    label {
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
    }

    input[type="radio"]:checked + label {
      color: black;
    }
  }

  .filter-heading {
    font-size: 16px;
    line-height: 14px;
  }

  .select-property-type {
    .select-box {
      select {
        border: 0px;
        outline: none;
        font-size: inherit;
        color: inherit;

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }

  .price-input-box {
    .enter-input {
      max-width: 45%;
      height: 40px;
      padding-left: 14px;

      input {
        width: 100%;
        font-size: 12px;
        line-height: 12px;
        color: inherit;
      }
    }
  }

  .user-multi-inputs {
    row-gap: 22px;
    column-gap: 13px;
    flex-wrap: wrap;
    justify-content: space-between;

    .one-input {
      max-width: 125px;
      height: 40px;
      padding-left: 5px;

      input {
        width: 100%;
        color: inherit;
        font-weight: 300;
        font-size: 11px;
        line-height: 14px;
      }
    }
  }

  .filter-btn-grp {
    button {
      height: 40px;
      width: 190px;
      border: 2px solid $dashboard-primary-theme-text;
      color: $repeated-text-color;
      font-size: inherit;
      transition: all 0.2s ease-in-out;

      &:hover {
        border: 2px $white;
        background: $repeated-text-color;
        color: $white;
      }
    }
  }

  .inner-shadow {
    box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    border: 1px solid $dashboard-primary-theme-text;

    .hero-select {
      padding-left: 10px;
      width: 100%;
      color: inherit;
      font-weight: 300;
      font-size: 15px;

      .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
      .MuiDataGrid-root .MuiDataGrid-cell:focus {
        outline: none !important;
        border: "none";
      }

      .MuiOutlinedInput-root {
        border: "none";
      }

      .MuiSelect-outlined {
        padding: 7px !important;
      }
    }
  }

  .modal-content {
    border: 1.5px solid $dashboard-primary-theme-text;
  }
}
.btn {
  border-radius: 20px;
}
.save-search-pop {
  .save-search-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $repeated-text-color;
  }
}
