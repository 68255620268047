@import '../../../style/_utilities.scss';






.virtual_office_page {
    padding: 30px;
    width: 100%;
    max-height: calc(100vh - 140px);
    overflow-y: scroll;
    scroll-behavior: smooth;
    border: 1px solid #315EFB;

    .left_carD {
        // margin-bottom: 18px;
        height: 80%;

        .Virtual_Office {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #585656;
        }

        .leo_text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height, or 117% */


            color: #585656;
        }

        .user_text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: $dashboard-primary-theme-text;
            // padding-bottom: 11px;
        }

        .user_images {}
    }

    .virtualCard-container {
        gap: 33px;

        .virtualoffice-card {
            width: 350px;
        }
    }


    .save_btnn {
        padding-top: 9px;
        padding-bottom: 9px;
        margin-top: 18px;
    }

    .left_card {
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 11.4224px;
            line-height: 22px;
            color: #585656;
        }
    }
}

.virtual_ofice_modal {
    .modal-dialog {
        .modal-content {
            background: #FDFDFD;
            border: 1px solid #315EFB;
            box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
            backdrop-filter: blur(20px);
            border-radius: 4px;
        }
    }
}

.virtual_modals_header {
    .btn-close {
        background-image: url(./images/close.svg);
        cursor: pointer;
        height: 20px !important;
        width: 20px !important;

    }
}

.virtual_modals_body {
    padding: 0px 50px 42px 50px;

    .inner_card_pading {
        padding: 38px 28px 40px 28px;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4px;
        margin-bottom: 28px;

        .MuiAutocomplete-hasClearIcon.css-l3ln04-MuiAutocomplete-root .MuiFilledInput-root {
            background: transparent !important;
            padding: 0;
            margin: 0;
        }

        .css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding: 0;
        }

        .input_namepad {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 144px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.5px;
            height: 32px;

            .red-star {
                color: red !important;
                font-size: 20px;

            }
        }

        .input-sec {
            width: 100%;

            .form-control:focus {
                box-shadow: none;
            }

            input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
                border-radius: 0px;
                background: transparent;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

    .create_btn {
        width: 262px;
        height: 35px;
    }

}