.image-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1001;

}

.image-popup.open {
    display: flex;
    // align-items: center;
    justify-content: center;
}

// .image-popup-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.7);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     opacity: 0;
//     visibility: hidden;
//     transition: opacity 0.3s ease, visibility 0.3s ease;
//     z-index: 1001;
// }

// .image-popup-overlay.open {
//     opacity: 1;
//     visibility: visible;
// }



.image-popup-content {
    position: relative;
    // max-width: 800px;
    max-height: 90vh;
    width: 100%;
    height: 100%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
        color: #fff;
        font-size: 35px;
    }
}