@import '../../../../../../style/utilities.scss';

.marketing-top-sec {
    gap: 20px;
}

.marketing-sec {
    padding: 17px 25px;
    border: 1px solid #9FCCFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    gap: 31px;
}

.marketing-bottom-sec {
    margin-bottom: 100px;
}

.marketing-message {
    color: $lite-black;
    font-size: 12px;
    line-height: 18px;

    .marketing-category {


        h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: $dashboard-primary-theme-text;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.marketing-table {
    .table {
        border: 2px solid #97AEFC;
        border-radius: 6px;
    }

    .descripton-col {
        max-width: 350px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .marketing-table-row {
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
    }

    .table>:not(:first-child) {
        border-top: 0px solid currentColor !important;
    }

    .table>thead {
        height: 72px;

        th {
            font-weight: 500 !important;
        }

        font-size: 16px;
        line-height: 16px;
        color: $white;
        background: $dashboard-primary-theme-text;
    }

    tr {
        border-bottom: 2px solid #97AEFC !important;
        border: none;
        border-color: transparent;
        vertical-align: middle;
    }

    p {
        padding-left: 25px;
    }

}

.long-btn {
    min-width: 160px;
    height: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}