@import "../../../../style/_utilities.scss";

.contact-sec {
  gap: 32px;

  .filter-bychar {
    max-height: 70vh;
    width: fit-content;
    overflow-x: hidden;

    .active-para {
      border: 1px solid $dashboard-primary-theme-text;

      .filter-para {
        color: $dashboard-primary-theme-text !important;
      }
    }

    .parameter {
      width: 24px;
      aspect-ratio: 1/1;
      border-radius: 50%;

      .filter-para {
        color: $lite-black;
        line-height: 3px;
      }
    }
  }

  .contact-main-sec {
    height: 70vh;
  }

  .tab-left-sec {
    .active-contact {
      border: 1px solid $dashboard-primary-theme-text;
      background: #ffff;
      width: 100.3%;
      height: 70px;
      border-right: 0px;
      z-index: 55;
    }

    .contact-details {
      gap: 15px;
      padding-left: 18px;

      .prof-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
      }

      .name {
        h6 {
          color: $dashboard-primary-theme-text;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
        }

        p {
          columns: $lite-black;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .contact-list {
      // height: calc(100vh - 230px);
      max-height: 82%;
      padding-inline-end: 10px;
      margin-right: 10px;
      color: #315efb;

      .current-contact {
        background-color: #315efb;
        color: #fff;
      }
    }

    .contact-card {
      border-radius: 4px;
      border: 1px solid $dashboard-primary-theme-text;
      min-height: 60px;
    }
  }

  .tab-right-sec {
    padding: 0;
    padding-left: 4% !important;
    border: 1px solid $dashboard-primary-theme-text;
    box-shadow: 12px 9.5px 15.49px rgba(0, 0, 0, 0.25);
    // height: calc(100vh - 140px);
    overflow-y: scroll;
    scroll-behavior: smooth;

    .person-info {
      .prof-img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid $dashboard-primary-theme-text;
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dashboard-primary-theme-text;
      }
    }

    .details-info {
      gap: 12px;

      .row {
        padding: 0px;
        margin: 0px;
      }

      .title-label {
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        line-height: 15px;
        color: $lite-black;
        text-transform: capitalize;
      }
    }

    .all-details {
      gap: 30px;
    }

    .edit-details {
      position: absolute;
      top: 1%;
      right: 1%;
      width: 40px;
    }
  }
}

.agent-virtual-office-dashboard {
  height: 80vh;

  .left-section {
    width: fit-content;
    // max-height: 60vh;
    overflow-y: scroll;
    padding-inline-end: 20px !important;
    gap: 15px;
    display: flex;
    flex-direction: column;

    // &::-webkit-scrollbar {
    //     margin-left: 10px;
    //     width: 10px;
    //     height: 10px;
    //   }

    .property-virtual-card {
      position: relative;
      width: 198px;
      padding: 5px;
      border-radius: 3.592px;
      background: #fdfdfd;
      box-shadow: 11.673454284667969px 14.81630802154541px 13.909369468688965px -9.751823425292969px
        rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(17.95915985107422px);

      .select-property {
        position: absolute;
        top: 3px;
        left: 3px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: transparent;
          border: 1px solid #ffcf3d;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 4px;
          top: 2px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .property-main-image {
        height: 53px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}

.property-listing-group {
}

.agent-virtual-office-dashboard {
  height: 80vh;

  .left-section {
    width: fit-content;
    overflow-y: scroll;
    padding-inline-end: 20px !important;
    gap: 15px;
    display: flex;
    width: fit-content;
    flex-direction: column;

    // &::-webkit-scrollbar {
    //     margin-left: 10px;
    //     width: 10px;
    //     height: 10px;
    //   }

    .property-virtual-card {
      position: relative;
      width: 198px;
      padding: 5px;
      border-radius: 3.592px;
      background: #fdfdfd;
      box-shadow: 11.673454284667969px 14.81630802154541px 13.909369468688965px -9.751823425292969px
        rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(17.95915985107422px);

      .select-property {
        position: absolute;
        top: 3px;
        left: 3px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: transparent;
          border: 1px solid #ffcf3d;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 4px;
          top: 2px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        margin: 0 7px;
        .details-left {
          color: #585656;
          font-family: Poppins;
          font-size: 8.293px;
          font-style: normal;
          font-weight: 400;

          .amount {
            color: #315efb;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
          }

          .area-name {
            font-size: 12px;
          }
        }

        .details-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .profile-image-parent {
            width: 27px;
            height: 30px;
            border-radius: 50%;

            img {
              border-radius: 50%;
            }
          }

          .profile-image-button {
            margin-bottom: 10px;
            margin-right: 1px;
            color: #315efb;
            border-radius: 2.562px;
            border: 0.64px solid #315efb;
            background: #fdfdfd;
            box-shadow: 8.325122833251953px 10.566502571105957px
              9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(48.0295524597168px);
            font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            padding: 2px 12px !important;
          }

          .buttons {
            color: #315efb;
            border-radius: 20px;
            border: 0.64px solid #315efb;
            background: #fdfdfd;
            box-shadow: 8.325122833251953px 10.566502571105957px
              9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(48.0295524597168px);
            font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            padding: 4px 12px !important;
          }
        }
      }

      .action-buttons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 4px;
        button {
          width: 100%;
          margin: 2px;
          color: #315efb;
          border-radius: 20px;
          border: 0.64px solid #315efb;
          background: #fdfdfd;
          box-shadow: 8.325122833251953px 10.566502571105957px
            9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(48.0295524597168px);
          font-family: Poppins;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          padding: 4px 12px !important;
        }

        .like {
          background: rgb(112, 224, 112);
          color: #fff;
        }

        .dislike {
          background: rgb(204, 57, 57);
          color: #fff;
        }
      }

      .noteshow-buttons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 4px;
        gap: 6px;
      }

      .add-note {
        background-color: #315efb;
        color: #fff;
        border-radius: 20px;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        border: none;
        padding: 4px;
        width: 100%;
      }
    }
  }

  .listing-footer {
    .select-all {
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .send-review {
      width: 90%;
      box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(75px);
      background: #315efb;
      border-radius: 20px;
      button {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        background: #315efb;
        padding: 10px;
        width: 100%;
        color: #fff;
        border-radius: 20px;
      }
    }
  }

  .right-section {
  }
}

.add-note__card {
  color: #585656;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent !important;

  .modal-dialog {
    .modal-content {
      border-radius: 10px;
      border: 1px solid #315efb;
      background: linear-gradient(
        136deg,
        rgba(253, 253, 253, 0.4) 0%,
        rgba(253, 253, 253, 0.1) 100%
      );
      box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(20px);
    }
  }

  .add-note__title {
    color: #4e62b1;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }

  .note-row {
    display: flex;
    padding-bottom: 10px !important;
    margin-left: 20px !important;
    border-bottom: 1px solid #546781;

    .note-heading {
      height: fit-content !important;
      margin-left: 20px !important;
      padding-right: 10px !important;
      margin-right: 10px !important;
      border-right: 1px solid #546781;
    }
  }

  .add-note__textarea {
    width: 100% !important;
    overflow: hidden;
    resize: none;
    height: auto;
    min-height: 50px;
  }

  .add-note__btn {
    border-radius: 4px;
    border: 2px solid #315efb;
    background: #fdfdfd;
    box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(75px);
    padding: 15px 75px 15px 75px;
  }
}

.interested_btn {
  background-color: #315efb !important;
  border-radius: 20px;
  color: white !important;
}

.main-content {
  height: calc(100% - 70px);
}

.map-box {
  border: 1px solid rgb(49, 94, 251);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.35);
}
