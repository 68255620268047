.documents-section {
    .create-buttons {
        justify-content: center;
        gap: 30px !important;

        .buttons {
            padding: 14px 124px;
            text-wrap: nowrap;
            border: 3px solid #315EFB;
            background: transparent;
            color: #315EFB;
            
            &:hover{
                border: 3px solid #fff;
                background: #315EFB;
                color: #fff;
            }
        }
    }
}

.documents-template-section{
    margin-left: 34px;
}