.quality_container {
    width: 100%;

    .qualityimg {
        max-width: 35%;
        padding: 5% 0% 5%;
        margin-left: 0;
    }

    .quality_text_content {
        width: 50%;
        height: 70vh;
        margin: 10% 0% 10%;
        background: #EFF3F6;
        padding: 8%;
        margin-left: 7%;
    }
}

@media screen and (min-width: 300px) and (max-width:1000px) {
    .quality_container {
        justify-content: center;
        display: block;
        flex-wrap: wrap;

        .qualityimg {
            max-width: 100%;
            height: auto;
            margin-left: 0% 0% 0%;
        }

        .quality_text_content {
            width: 100%;
            margin-top: 0%;
            padding: 0%;
            margin-left: 0%;
            height: auto;

        }

        .second_text {
            margin-top: 0%;
        }

        .app-blue-btn {
            width: 100%;
        }
    }



}

// 