@import "./../../../../../style/utilities";


.stopwatch_mainsection{
    padding: 25px;
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .position_absulute{
        position: absolute;
        top: -65%;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
    }
    .nav-tabs .nav-link {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        color: #585656;
    }

    .nav-tabs {
        border: 0px;
        width: fit-content;
        .nav-link {
            border: 0px;
        }
    }

    .nav-tabs .nav-link.active {
        background: transparent;
        outline: none;
        color: #315efb;
        border-bottom: 4px solid #315efb;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
    }

    .select_towns {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #585656;
    }

    .datES_section{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        align-items: center;
    }

    
}
