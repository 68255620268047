@import "../../../../style/utilities.scss";

.agentabout_head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4e62b1;
}

.agentbio_start_ratings {
  .checked {
    color: orange;
  }

  display: flex;
}

.agentbio_text1 {
  // margin-right: 1rem;
  max-width: 98%;
}

.team-card {
  width: 175px;
  min-height: 220px;
  gap: 15px;

  p {
    font-size: 12.8485px;
    line-height: 11px;
    color: $lite-black;
  }

  .prof-img {
    width: 102px;
    height: 108px;
  }
}

.agent-team-section {
  padding-left: 15px;
  padding-bottom: 17px;

  .leo-team {
    font-size: 18px;
    line-height: 14px;
    color: $repeated-text-color;
  }
}
