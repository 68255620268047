@import '../../../style/utilities.scss';

.profile-sec {
    .profile-left-tab {
        padding: 0;
        z-index: 2;

        // max-width: 220px;
        .public-view {
            text-decoration: none;
            color: inherit;
        }
    }

    .tab-middle-sec {
        padding: 0;
        border: 1px solid $dashboard-primary-theme-text;
        // box-shadow: 12px 9.5px 15.49px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(20px);
        border-radius: 44px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        // min-height: calc(100vh - 170px);
        height: calc(100vh - 140px);
        overflow-y: scroll;
        scroll-behavior: smooth;




        // display: flex;
        // justify-content: center;
        // align-items: center;
        // width: 85%;
        // max-width: 1053px;

        // .tab-content {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        // }
    }

    .nav-pills .nav-link {
        padding: 19px;
        padding-right: 0;
        font-size: 16px;
        line-height: 16px;
        color: $lite-black;
    }

    .nav-pills {
        gap: 0px;
    }

    .nav-pills .nav-link.active {
        border: 1px solid #315EFB;
        // box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.15);
        box-shadow: -4px 5px 5px rgba(0, 0, 0, 0.20);
        background: transparent;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        color: $dashboard-primary-theme-text;
        border-right: 0px;
        // width: 100.3%;
        width: 98.7%;
        background: #FDFDFD;

    }
}

@media screen and (max-width: 992px) {

    .profile-sec .nav-pills .nav-link {
        padding: 5px;
    }

    .profile-sec .nav-pills .nav-link.active {
        border: 1px solid #315EFB;
        border-right: 1px solid;
        border-radius: 4px;
        padding: 5px;

    }

    .tab-switch-items {
        justify-content: space-evenly;
    }

    .profile-sec-container {
        flex-direction: column;
        margin: 0px;
        row-gap: 20px;
    }

    .main-dashboard .dashboard-middle {
        padding-right: 0px;
    }
}