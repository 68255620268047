@import "../../../../../../../style/_utilities.scss";

.main_customeRange_section {
    .SelectDate {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        padding: 14px 40px;
    }

    .scrol_width .scrolls_stopwatch {
        max-height: 350px !important;
    }
}





// modal code

.costome_range_modal {
    .costome_text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #315EFB;
    }

    .modalBodycalanDER_section {
        .rdrDateRangePickerWrapper {
            width: 100% !important;
        }
    
        .rdrDefinedRangesWrapper {
            display: none;
        }
    
        .rdrCalendarWrapper {
            width: 100% !important;
    
            .rdrDateDisplayWrapper {
                background: rgba(49, 94, 251, 0.7) !important;
                border: 2px solid #FFFFFF !important;
                border-radius: 5.6625px 5.6625px 5.66px 5.66px !important;
    
                .rdrDateDisplayItem {
                    border-radius: 0px;
                    background: rgba(49, 94, 251, 0) !important;
                    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0);
                    border: 0px solid transparent;
                }
    
                .rdrDateDisplayItem input {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 12px;
                    color: #FFFFFF;
                    height: 45px;
                }
    
                .rdrDateDisplayItemActive input {
                    color: #FFFFFF;
                }
    
                .rdrDateDisplay {
                    margin: 0em;
                }
            }
    
            .rdrWeekDay {
                font-style: normal;
                font-size: 14px;
                line-height: 12px;
                color: #315EFB;
                margin-bottom: 38px;
    
            }
    
            .rdrDayNumber span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 12px;
                color: #585656;
    
            }
        }
    
        .rdrMonths {
            width: 100% !important;
    
            .rdrMonth {
                width: 100% !important;
            }
    
            .rdrMonth:first-child {
                border-right: 2px solid #6C8CFA;
            }
        }
    
        .save__btn {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #315EFB;
            padding: 14px 60px;
        }
    }
}