@import "../.././.././.././.././style/_utilities.scss";

.change-passbtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #315efb;

  &:hover {
    color: #315efb;
  }
}

.change-passborder {
  background: linear-gradient(157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%);
  border: 2px solid #315efb;
  box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
  border-radius: 2px;

  &:hover {
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 2px;
    background: #315efb;
  }
}

.password_pagpading {
  padding: 40px 45px;

  .input_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #585656;
  }

  .Passwordsec_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #315efb;
    padding-bottom: 43px;

  }



  .main_input {
    border-bottom: 0.676897px solid #546781;
    padding-bottom: 4.7px;
    // margin-bottom: 20px;


    .input_namepad {
      padding-right: 8.75px;
      border-right: 0.676897px solid #546781;
      width: 180px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 3.4px;

      .red-star {
        color: red !important;
        font-size: 20px;
      }
    }

    .input-sec {
      width: 100%;
      display: flex;

      .form-control:focus {
        box-shadow: none;
      }

      input {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        border-radius: 0px;
        background: transparent;
      }
    }
  }

  .change-passbtnpad {
    padding: 14px 35px;
  }

  // .height_w {
  //   height: 40px;
  //   width: 127px;
  // }

  .remove_border {
    background: linear-gradient(157.69deg,
        rgba(255, 255, 255, 0.1) 6.02%,
        rgba(255, 255, 255, 0) 95.12%);
    border: 2px solid #ff3838;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ff3838;

    &:hover {
      border: 2px solid #ffffff;
      background: #ff3838;
      color: #ffffff;
    }
  }
}

// modal css

.forgot_modals {
  .modal-dialog {
    .modal-content {
      background: #fdfdfd;
      border: 1px solid #315efb;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(20px);
      border-radius: 4px;
    }
  }
}

.forgotmodal_body {
  .forgot_title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #315efb;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .forgot_small_text {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
    color: $lite-black;
  }

  .main_input {
    border-bottom: 0.676897px solid #546781;
    padding-bottom: 4px;
    margin-bottom: 18px;

    .input_namepad {
      padding-right: 8.75px;
      border-right: 0.676897px solid #546781;
      width: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
      padding-bottom: 3.5px;

      .red-star {
        color: red !important;
        font-size: 20px;
      }
    }

    .input-sec {
      width: 100%;

      .form-control:focus {
        box-shadow: none;
      }

      input {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        border-radius: 0px;
        background: transparent;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }

  .submit_btn {
    background: linear-gradient(157.69deg,
        rgba(255, 255, 255, 0.1) 6.02%,
        rgba(255, 255, 255, 0) 95.12%);
    border: 2px solid #315efb;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #315efb;
    height: 40px;
    width: 127px;
    // padding: 14px 49px;

    &:hover {
      box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
      color: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 2px;
      background: #315efb;
    }
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #585656;
    text-decoration: none;

    span {
      color: #315efb;
    }
  }


}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background-image: url(./images/close.svg);
  cursor: pointer;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.old-pass {
  .validation-text{
    text-align: end;
  }
  .col-md-12 {
    margin-bottom: 24px;
  }
}