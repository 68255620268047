.card-parent {
  display: flex;
  gap: 21px;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 75vh;
  scroll-behavior: smooth;

  .guest-virtual-card {
    .guest-property-virtual-card {
      position: relative;
      width: 259px;
      height: 185px;
      box-shadow: 10px 12px 12px -8px rgba(0, 0, 0, 0.35);

      .select-property {
        position: absolute;
        top: 3px;
        left: 3px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: transparent;
          border: 1px solid #ffcf3d;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 4px;
          top: 2px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .property-main-image {
        height: 82px;
        width: 100%;
        object-fit: cover;
      }

      .details {
        display: flex;
        justify-content: space-between;

        .details-left {
          color: #585656;
          font-family: Poppins;
          font-size: 8.293px;
          font-style: normal;
          font-weight: 400;

          .amount {
            color: #315efb;
            font-family: Poppins;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            padding-right: 14px;
            border-right: 1px solid #bbb;
          }

          .area-name {
            font-size: 14px;
          }
        }

        .details-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .profile-image-parent {
            width: 27px;
            height: 30px;
            border-radius: 50%;

            img {
              border-radius: 50%;
            }
          }

          .profile-image-button {
            margin-bottom: 10px;
            margin-right: 1px;
            color: #315efb;
            border-radius: 2.562px;
            border: 0.64px solid #315efb;
            background: #fdfdfd;
            box-shadow: 8.325122833251953px 10.566502571105957px
              9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(48.0295524597168px);
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            padding: 2px 12px !important;
          }

          .buttons {
            color: #315efb;
            border-radius: 20px;
            border: 0.64px solid #315efb;
            background: #fdfdfd;
            box-shadow: 8.325122833251953px 10.566502571105957px
              9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(48.0295524597168px);
            font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            padding: 4px 12px !important;
          }
        }
      }

      .action-buttons {
        display: flex;
        justify-content: space-around;

        button {
          color: #315efb;
          border-radius: 2.562px;
          border: 0.64px solid #315efb;
          background: #fdfdfd;
          box-shadow: 8.325122833251953px 10.566502571105957px
            9.91970443725586px -6.954679489135742px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(48.0295524597168px);
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          padding: 4px 8px !important;
        }

        .like {
          background: rgb(112, 224, 112);
          color: #fff;
        }

        .dislike {
          background: rgb(204, 57, 57);
          color: #fff;
        }
      }

      .add-note {
        background-color: #315efb;
        color: #fff;
        border-radius: 2px;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        border: none;
      }
    }

    .likedislike {
      margin-top: 23px;
      // display: inline-flex;
      display: flex;
      justify-content: center;
      gap: 10px;

      .buttons {
        color: #ff3838;
        width: 122px;
        border: 1px solid #ff3838;
        border-radius: 20px;
        padding: 8px;
        background: transparent;

        svg {
          color: #ff3838;

          path {
            stroke: #ff3838;
          }
        }
      }

      .interested {
        color: #2fc40b;
        width: 122px;
        text-wrap: nowrap;
        border: 1px solid #2fc40b;
        border-radius: 20px;
        padding: 8px;
        background: transparent;

        svg {
          color: #2fc40b;

          path {
            stroke: #2fc40b;
          }
        }
      }
    }
  }
}

.interested-properties {
  p {
    color: #5076fb;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-transform: uppercase;
  }
}

.add-note-modal-parent {
  .modal-backdrop {
    .show {
      opacity: 0;
    }
  }
}

.add-note-modal {
  .modal-content {
    border-radius: 10px !important;
    border: 1px solid #315efb !important;
    background: linear-gradient(
      136deg,
      rgba(253, 253, 253, 0.4) 0%,
      rgba(253, 253, 253, 0.1) 100%
    ) !important;
    box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.35) !important;
    backdrop-filter: blur(20px) !important;
  }

  .custom-heading-note {
    color: #4e62b1;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 100% */
  }
  .form-control {
    background-color: transparent;
  }
}

.main-content {
  height: calc(100% - 70px);
}
