@import '../../../../../style/utilities.scss';

.sms_marketing_form {
    width: 100%;

    .pad_cards {
        padding: 25px 50px;
        margin-bottom: 24px;

        h6 {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #315EFB;
        }

        .marketing-row {
            margin-bottom: 20px;
        }

        .input_section {
            .main_input {
                border-bottom: 0.676897px solid #546781;
                padding-bottom: 4.7px;
                // margin-bottom: 20px;

                .input_namepad {
                    padding-right: 8.75px;
                    border-right: 0.676897px solid #546781;
                    // width: 200px;
                    display: flex;
                    // justify-content: end;
                    align-items: flex-end;
                    padding-bottom: 3.4px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #585656;

                    .red-star {
                        color: red !important;
                        font-size: 20px;

                    }
                }

                .input-sec {
                    // width: 100%;
                    flex: 1;

                    .react-datepicker__view-calendar-icon input {
                        padding-left: 15px !important;
                    }

                    .form-control:focus {
                        box-shadow: none;
                    }

                    input,
                    select {
                        width: 100%;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                        color: #585656;
                        border-radius: 0px;
                        background: transparent;
                    }

                    .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
                        border-bottom: 0;
                    }

                    .css-1q60rmi-MuiAutocomplete-endAdornment {
                        display: none;
                    }
                }


            }

            .text_area {
                .input_namepad {
                    height: 100%;

                }



                .form-control {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #585656;
                }
            }

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #315EFB;
            }

            .upload_text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #585656;
            }
        }

        .file-input_sec {
            flex: 1;
            max-width: 47%;
            min-width: 200px;
            gap: 5.84px;
            margin-bottom: 20px;

            .img-upload {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #585656;
            }

            input {
                box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
                border-radius: 2px;
                border: 2px solid #FFFFFF;
                background: transparent;
                height: 34px;
                width: 100%;
            }

            input::-webkit-file-upload-button {
                background: $dashboard-primary-theme-text;
                border: none;
                border-right: 2px solid #FFFFFF;
                font-family: "Poppins" !important;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                height: 100%;
                cursor: pointer;
                width: 40%;
            }

            input::file-selector-button {
                background: $dashboard-primary-theme-text;
                border: none;
                border-right: 2px solid #FFFFFF;
                font-family: "Poppins" !important;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                height: 100%;
                cursor: pointer;
                width: 40%;
            }
        }
    }

    .marketing-form-btn {
        button {
            border-radius: 100px;
            background: $dashboard-primary-theme-text;
            color: $white;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            width: 121px;
            height: 40px;
        }
    }

    .input-row {
        border-bottom: 1px solid $lite-black;
        font-size: 12px;
        line-height: 12px;
        color: $lite-black;

        .input-title {
            padding-right: 5px;
            border-right: 1px solid $lite-black;
        }

        .text-input {
            padding-left: 5px;
        }
    }
}