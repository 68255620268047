.Adv_dummy_content {
    background-image: linear-gradient(90deg, #EFF3F6 50%, #fff 50%);
    align-items: center;
    position: relative;

    .row {
        flex-shrink: 0;
        padding-right: calc(var(--bs-gutter-x) * .0);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
        height: 85vh;

        .dummy_img {
            width:55% ;
            height: 90%;
            padding: 0;
            position: absolute;
            right: 0%;
        }        
    }
}

.stu-Lorem {
    width: 70%;
    padding: 1% 0% 2%;
    padding-left: 30%;
    text-align: center;
    font: 500 30px/35px Poppins;

}

.app-white-btn {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    color: #43A4F6;
    border: 2px solid #43A4F6;
    font: 400 16px/1.2 Poppins;
}

.stu_text-content {
    padding: 0% 5% 7%;
    margin-top: 10%;

}


@media screen and (min-width: 300px) and (max-width:1000px) {
    .stuning_content{
        margin-bottom: 5%;
        height: 100vh;
    }

    .Adv_dummy_content {
        background-image: linear-gradient(90deg, #EFF3F6 100%, #fff 0%);

    }    
    .stu-Lorem {
        width: 90%;
        font-size: 15px;
        padding-left: 0%;
    }
    .Adv_dummy_content .row .dummy_img {
        width: 100%;
        height: auto; 
        padding: 0;
        position: unset;
        right: 0%;
        // padding-bottom:5% ;
    }

}