.sub_plan {

    // overflow-y: scroll;
    // height:50vh;
    // padding-right: 20%;
    h2 {
        animation: bounce;
        animation-duration: 2s;
        font: 500 26px/28px Poppins;
        color: #4E62B1;

    }

    .plan-body {
        height: 55vh;
    }

    .plans {
        font: 500 15px/20px Poppins;
        // padding: 0% 3% 0%;
        margin-top: 2%;
        color: #4E62B1;

        .me-2 {
            .right_mark {
                color: #4BAE4F;
            }
        }
    }
}