.loginpage_body {
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.login_body {
  border: 5px #4568F1 solid; 
  display: flex;
  align-items: center;
  background: white;
  border-radius: 25px;
  padding: 5px;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.login_body_right {
  width: 50%;
  background-color: #4568F1;
  border-radius: 25px;
  padding: 50px 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginpage_form{
    max-width: 450px;
    width: 100%;
  }
}

.login_body_left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-logo{
    width: 100%;
    max-width: 500px;
  }
}

.login-logo {
  width: 50%;
  max-width: 500px;
}

.loginpage_form a {
  color: #CFF4FF;
}

.loginpage_form input {
  background-color: white;
  border-radius: 20px;
}

.loginpage_text {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loginpage_text_head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: white;
}

.loginpage_text_subhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #9c9c9c;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 485px;

  img {
    width: 25px;
    margin: 0px 10px;
  }
}

.input-container span {
  color: white;
  margin: 10px;
}

.loginpage_signin {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.icon {
  font-size: 1.2rem;
  padding: 10px;
}

.input-field {
  width: 100%;
  padding: 8px;
  outline: none;
}

.loginpage_inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .log-validation {
    align-self: flex-start;
  }
}

.loginpage_disclaimer-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: white;
  width: 295px;
  padding-bottom: 15px;
  padding-top: 10px;
}

.loginpage_disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.loginpage_help_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 485px;
  margin: auto;
}

.loginpage_forgot_password {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

button.loginpage_signin_btn {
  width: 143.57px;
  height: 44.69px;
  background: white;
  border: none;
  border-radius: 50px !important;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.69889px;
  color: black
}

.loginpage_signin_btn_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: black;
}

.loginpage_social_login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

button.loginpage_fb_login,
.loginpage_google_login {
  max-width: 485.6px;
  width: 100%;
  height: 49px;
  background: white;
  border-radius: 3.69889px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  text-align: center;
  color: black;
  padding: 15px;
}

button.loginpage_google_login {
  background: white;
  color: black;
  margin-top: 0.5rem;
}

.loginpage_fb_login,
.loginpage_google_login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #43a4f6;
}

.loginpage_signup_help {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.loginpage_signup_link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  color: #CFF4FF;
}

.loginpage_signup_text {
  font-family: "Poppins";
  font-size: 14px;
  color: white;
}

.forget-page {
  .loginpage_form {
    min-height: 350px;
  }

  .loginpage_help_button {
    margin: inherit;
  }

  .loginpage_text_subhead {
    margin-top: 7px;
    line-height: inherit;
    max-width: 485px;
    color: white;
    text-align: center;
  }
}

.login-divider {
  width: 1px;
  height: 22px;
  background-color: #546781;
}

.loader-popup {
  .modal-dialog .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    box-shadow: none;
    width: 0px;
    height: 0px;
  }

  .modal-body {
    // --bs-backdrop-opacity: 0.5;
    transform: translate(150%, 0%);
    position: absolute;

  }
}