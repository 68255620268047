@import '../../../../../../style/utilities.scss';

.small-sidebar {
    min-width: 45px;
    // max-height: 596px;
    // border-radius: 10px;
    max-height:  83.8vh;
    border-radius: 0px 35px 0px 44px;
    background: $sidebar-primary;
    color: $white;
    padding-top: 52px;
    padding-bottom: 50px;
    box-shadow: -3px 2px 2px $sidebar-primary ;
    // left: -20px;

    @media screen and (max-width: 992px) {
        display: none !important;
    }

    .sidebar-links {
        gap: 20px;
    }

    .link-icon {
        width: 14px;
        height: 14px;
    }
}