@import '../../../../../style/utilities.scss';

.topbar {
    padding: 24px;

    .listing-count {
        color: $dashboard-primary-theme-text;
    }
}

.logo {
    width: 123.32px;
    height: 35px;
}

.power-icon {
    width: 40px;
    height: 40px;
    box-shadow: 11px 10.5px 9.49px -6.86px rgba(0, 0, 0, 0.40);
    // box-shadow: $box-shadow;
    border: 2px solid $dashboard-primary-theme-text;
    border-radius: 4px;

    img {
        width: auto;
    }
}

.first {
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2) !important;
}

.second {
    box-shadow: 5px 5.5px 5.49px rgba(0, 0, 0, 0.3) !important;
}

.third {
    box-shadow: 4px 4.5px 5.49px rgba(0, 0, 0, 0.4) !important;
}



.user-info {
    .agent-rating {
        font-size: 1rem;
    }

    p {
        font-size: 16px;
        // line-height: 38px;
        color: $dashboard-primary-theme-text;
    }
}