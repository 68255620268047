@import '../../../../style/_utilities.scss';

.modal-content {
    background-image: linear-gradient(to right, #EEF2F5 44.67%, #D8E2EA 118.54%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    box-shadow: 10.99px 10.37px 23.81px rgba(34, 34, 34, 0.33);
    backdrop-filter: blur(5px);
    border-radius: 4px;
}

.modal-header {
    border: none;
}

.modal-body {
    font-family: 'Poppins';
    font-size: 12px;
    color: #585656 !important;

    .property-image-modal {

        .img-preview {
            cursor: move !important;
            width: 150px;
            aspect-ratio: 1/1;
        }

        // .animated {
        //     background: yellow;
        //     overflow: hidden !important;
        //     position: relative !important;
        //     transition: 0s none !important;
        //     transform: none !important;
        //     transition-duration: 0ms;

        //     .dragHandleSelector {
        //         position: relative !important;
        //         transition: none;
        //         top: 0;
        //     }
        // }

        // .smooth-dnd-draggable-wrapper {}

    }

    p {
        color: #585656;
    }
}

.modal-title {
    p {
        font-family: 'Poppins';
        font-size: 14px;
        color: #4E62B1;
    }
}

.modal-footer {
    border: none;
}


.singleCat {
    input[type="checkbox"] {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
    }
}

.singleBOx {
    overflow: hidden;
    width: 80px;
    height: 80px;
    background: #F7F9FB;
    border: 2px solid #FFFFFF;
    border-radius: 4px;

    .petImg {
        height: 100%;
    }
}

input[type="checkbox"]:checked+label {
    .singleBOx {
        border: 1px solid #4E62B1;
        color: #4E62B1;
    }

    p {
        color: #4E62B1;
    }
}


.modal-footer {
    button {
        border: 2px solid #4E62B1;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 2px;
        height: 40px;
        width: 127px;
        color: #4E62B1;
        text-transform: none;
    }
}

.min-modal-btn {
    border-radius: 4px;
    width: 89px;
    height: 40px;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
}

.day-slot {
    .min-show{
        background: #d2dde8;
        border: 1px solid #7f7878;
    }
    .time-input {
        position: absolute;
        z-index: -1;
    }

    label {
        background: #F7F9FB;
        border: 2px solid #FFFFFF;
        color: $lite-black;
    }

    input[type="checkbox"]:checked+label {
        color: #F7F9FB;
        border: 2px solid #fdfdfd;
        background-color: $lite-black;
    }
}