@import "../../../../style/_utilities.scss";

.sel_person_section {
  .search-box {
    gap: 20px;
    max-width: 486px;

    .search {
      max-width: 426px;
      border: 2px solid $dashboard-primary-theme-text;
      box-shadow: $box-shadow;
      backdrop-filter: blur(75px);
      border-radius: 4px;
      padding: 6px 19px;
      background: $off-white;

    }

    .search-icon {
      background: red;
    }

    .filter-icon {
      width: 40px;
      height: 40px;
      box-shadow: $box-shadow;
      background: $off-white;
      border: 2px solid $dashboard-primary-theme-text;
      border-radius: 4px;

      img {
        width: auto;
      }
    }
  }

  .all_savebtn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 10px 30px;
  }
}

// form
.form_selperson {
  .card_borders {
    // max-width: 643px;

    .card-body {
      padding: 0px;


      .lable_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: $lite-black;
      }

      input {
        background: #fdfdfd;
        border: 1px solid $dashboard-primary-theme-text !important;
        box-shadow: 5px 5.5px 12px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(75px);
        font-family: "Poppins";
        color: $lite-black;
        border-radius: 4px;
      }

      .form_select {
        border: 1px solid $dashboard-primary-theme-text !important;
        box-shadow: 5px 5.5px 12px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(75px);
        border-radius: 4px !important;
        color: $lite-black;
        padding: 0.375rem 0.75rem;
      }

      .apply_btn {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        padding: 14px 76px;
      }

    }
  }



}