@import "../../../../../../../style/_utilities.scss";

.Manhatten_sectioNS {


    .scrollS {
        max-height: 250px;
        overflow-x: scroll;
        overflow-y: hidden;
        // overflow-y: scroll;
        scroll-behavior: smooth;
        // padding-inline-end: 24px;
        // padding-top: 15px;

        &::-webkit-scrollbar {
            height: 6.62px;
        }

        &::-webkit-scrollbar-track {

            background: white;
            border-radius: 94.5483px;
            position: relative;
        }

        &::-webkit-scrollbar-thumb {
            height: 4.73px;
            background: #315EFB;
            border-radius: 94.5483px;
            cursor: pointer;
        }
    }


    .form-check-label {
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        padding-top: 3px;
    }

    // input {
    //     height: 12px;
    //     width: 12px;
    // }

    input::-webkit-file-upload-button {
        max-width: 180px;
    }

    .form-check {
        display: flex;
        align-items: center;
        width: 18%;
    }

    .upload_minimun_text {
        // padding-top: 27px !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #585656;
    }

    .link-conatiner {
        gap: 15px;
        margin-top: 20px;
    }

    .link_text {
        // padding-top: 19px !important;
        width: fit-content;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 10px;
        color: #43A4F6;
        text-decoration: none;
        margin: 0;
    }

    .info_text {
        padding-top: 12px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #315EFB;
        margin-bottom: 30px !important;
    }

    .fileupload {
        max-width: 100%;

        p {
            font-size: 16px;
            text-transform: capitalize;
            letter-spacing: 0.2px;
        }

        label {
            background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
            border-radius: 2px;
            // margin: 0px 2px;
            border: 2px solid white;

            span {
                &:first-child {
                    flex: 0 0 150px;
                    max-width: 150px;
                    text-align: center;
                    padding: 10px;
                    background-color: #315EFB;
                    color: white;
                    font-size: 14px;
                    line-height: 14px;
                }

                &:last-child {
                    flex: 0 0 calc(100% - 150px);
                    max-width: calc(100% - 150px);
                    align-items: center;
                    display: flex;
                    padding: 10px;
                    font-size: 14px;
                    line-height: 14px;
                    color: #585656;
                }
            }
        }
    }

    .save___btns {
        font-size: 12px;
        line-height: 12px;
        color: #315EFB;
        padding: 14px 48px;
        background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        border: 2px solid #315EFB;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 2px;

        &:hover {
            color: white;
            background: #315EFB;
            border: 2px solid white;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 2px;
        }
    }
}