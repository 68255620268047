// @import '../../../../../style/utilities'


    .sub_card{
        width: 100%;
        .sub_p_card{                       
            // color:$repeated-text-color; 
            color : #4E62B1;
            // width: 450px;
            // height: 500px;
            border-radius: 6px;
            border: 2px solid #ffffff;
            background: #EDEFF7;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


            .plan_card{
                font: 500 15px/20px Poppins;

                .sub_heading{
                    font: 500 20px/26px Poppins;
                }
                .plan_rate{
                    font: 500 40px/40px Poppins;

                }
                .rightMark{
                    color: #4BAE4F;

                    }
                    .subscrib{
                    font: 500 15px/20px Poppins;

                    }
            }
            .sub_btn{
                width: 100%;
                background : #4E62B1;
                color: #ffffff;
            
            
            }
        }
    }
// font: 400 16px/1.2 Poppins;
