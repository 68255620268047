@import "../../../style/utilities.scss";

.expireD_listing_page {
    width: 100%;

    .curren_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 14px;
        color: $dashboard-primary-theme-text;
    }

    .remove_btn {
        width: 110px;
        height: 40px;
    }

    .longer_btN {
        font-size: 16px;
        width: 110px;
        height: 40px;
        margin-right: 14px;
    }

    .serch_droupdown {
        width: 300px;
        border: 2px solid #315efb;
    }

    table {
        margin-top: 15px;

        thead tr th {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #585656;
            padding-bottom: 0px;
        }

        tbody tr {
            background: #fdfdfd;
            outline: 1px solid #315efb;
            box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
            border-radius: 4px !important;

            &:hover {
                background: linear-gradient(90deg, rgba(66, 106, 248, 0.723) 0%, rgba(49, 94, 251, 0.7596288515406162) 38%, rgba(50, 39, 39, 0) 100%);
                border: 0.94375px solid $dashboard-primary-theme-text;
                color: $white;
            }

            .address_width {
                width: 100px;
                display: table-cell;
            }

            td {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #585656;
                background: transparent;

                .edit-listing {
                    color: $dashboard-primary-theme-text;
                    cursor: pointer;
                }

                .copy-link {

                    svg {
                        rotate: 313deg;
                        color: $dashboard-primary-theme-text;
                    }
                }

                .share-proprty-btn {
                    // background: red;
                    width: fit-content;
                    height: 15px;
                    width: 15px;

                    .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
                        background: transparent !important;
                        width: auto !important;
                        height: auto !important;

                        min-height: auto !important;
                    }

                    button {
                        // background: rgba(121, 118, 118, 0.269);
                        background: transparent !important;
                        box-shadow: none;
                        padding: 0;
                        margin: 0;

                        svg {
                            color: $dashboard-primary-theme-text;
                            font-size: 20px;
                        }
                    }
                }

                img {
                    max-height: 65px;
                    width: 65px;
                    object-fit: scale-down;
                }
            }


        }
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 20px;
    }


}

// table{
//     display: flex;
//     flex-direction: column;
// }

// tbody, td, tfoot, th, thead, tr {
//     width: 100%;
//     display: flex;
// }

// .table>tbody {
//     vertical-align: inherit;
//     flex-direction: column;
//     gap: 27px;
// }

// ==============================sawan