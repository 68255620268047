.Advertise6 {
    position: relative;
    isolation: isolate;
    z-index: -1;
    padding: 0% 5% 0%;
    width: 100%;
    height: 100vh;
    .dream_text {
        width: 50%;
        padding-left: 7.5%;
    }

    .dream_content {
        align-items: center;
        gap: 0%;

        .dreamHeading {
            justify-content: start;
            width: 50%;
            padding-left: 2.8%;
        }
    }
    .Img_Content3 {
        justify-content: center;
        height: 80vh;
        width: 100%;
        display: flex;

        .dreamImg1 {
            max-width: 650px;
            z-index: 10;
            margin: 0% 1% 0%;
        }
        .dreamImg2 {
            z-index: 1;
            margin: 0% 1% 0%;
        }
    }

    .dowtImg4 {
        position: absolute;
        width: 24%;
        transform: translateX(-18%);
        bottom: 3.5%;
        position: absolute;
        left: 40.5%;
        z-index: -1;
    }
}

@media screen and (min-width: 360px) and (max-width:1000px) {
    .Advertise6 {
        position: unset;
        width: 100%;
        padding: 2%;
        margin-top: 5%;

        .dream_content {
            width: 100%;

            .number_3IMg {
                max-width: 55%;
            }

            .dreamHeading {
                width: 100%;

                h2 {
                    font-size: 18px;
                }
            }
        }

        .descrip_dream_text {
            margin-top: 5%;

            .dream_text {
                width: 100%;
                font-size: 14px;
                padding-left: 0%;
            }
        }

        .Img_Content3 {
            justify-content: center;
            display: block;
            margin-left: 0%;
            width: 100%;

            .dreamImg1 {
                width: 100%;
                margin: 0% 0% 0%;
            }

            .dreamImg2 {
                width: 100%;
                margin: 5% 0% 0%;
                .respo_img{
                height: 45vh;
            }
            }
        }

        .dowtImg4{
            display: none;
        }

    }
}