.review_card {
  height: 213px;
  width: 175px;
  background: 
//   url(360_F_237711475_9ht6fsO3yhDKpB49g1QOHqCAaYsHvJHu.jpg),
    linear-gradient(
    157.69deg,
    rgba(255, 255, 255, 0.5) 6.02%,
    rgba(255, 255, 255, 0) 95.12%
  );
  box-shadow: 8.77768px 11.1409px 10.4589px -7.33274px rgba(34, 34, 34, 0.33),
    inset 0px 0px 13.5553px 2.71106px rgba(85, 85, 85, 0.1);
  border-radius: 5.40165px;
}
img.review_card_data_image {
  width: 74.35px;
  height: 75.55px;
  // background: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0ykhTZJGPHePtQbZPztm4nX3GHOv0FLwgqg&usqp=CAU");
  border: 1.11923px solid #ffffff;
  border-radius: 5.57501px;
}
.review_card_data_name_text {
  width: 91px;
  height: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 8px;
  /* identical to box height, or 56% */

  color: #4e62b1;
}

.checked {
  color: orange;
}

button.review_card_data_btn {
  height: 30px;
  width: 85px;
  background: 
//   url(360_F_237711475_9ht6fsO3yhDKpB49g1QOHqCAaYsHvJHu.jpg),
    linear-gradient(
    157.69deg,
    rgba(255, 255, 255, 0.1) 6.02%,
    rgba(255, 255, 255, 0) 95.12%
  );
  border: 1px solid #4e62b1;
  box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
  border-radius: 2px;
}

.review_card_data_text2 {
  width: 113px;
  height: 15px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #585656;
}
.review_card_data_btn_text {
  width: 42px;
  height: 10.57px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* or 100% */

  color: #4e62b1;
}
.review_card_data {
  //   margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.review_card_data_main {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@import "../../../../style/_utilities.scss";
// sawan
.reviews_card_main {
  
  .inner_card{
    height: 230px;
    // margin-right: 38px;
    // margin-bottom: 38px;
    .card_pading{
      padding: 16px 30px;
    }
      img {
    border: 1.11923px solid #ffffff;
    border-radius: 5.57501px;
    width: 75px;
    height: 75px;
    margin-bottom: 14px;
  }

  h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 8px;
    color: $dashboard-primary-theme-text;
    margin-bottom: 10px !important;
  }

  .Review_btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 10px 22px;
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #585656;
  }
  }

}
