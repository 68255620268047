// sawan css
@import "../../../style/_utilities.scss";

.savelisting_main {
  .listing-body {
    padding: 10px;
    max-height: calc(100vh - 260px);
  }

  .save_listingP {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 14px;
    color: $dashboard-primary-theme-text;
  }

  .remove_btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #ff3838;
    width: 185px;
    height: 40px;
    border-radius: 20px;
    svg {
      font-size: 16px;
    }
  }

  .longer_btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    border-radius: 20px;
    // line-height: 18px;
    color: $dashboard-primary-theme-text;
    height: 40px;
    width: 248px;
    svg {
      font-size: 16px;
    }
  }

  .sort-by {
    p {
      font-size: 12px;
      line-height: 12px;
      color: $repeated-text-color;
    }

    select {
      max-width: 155px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid $dashboard-primary-theme-text;
      font-size: 12px;
      line-height: 12px;
      color: $dashboard-primary-theme-text;

      &:focus {
        outline: 0px;
      }
    }
  }

  .prise_droupdown {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: #000000 !important;
    padding-top: 14px;
    padding-bottom: 14px;
    background: transparent;
    padding-left: 13px;
    padding-right: 20px;
  }

  .custome_droupdown {
    margin-bottom: 31px;

    .dropdown-toggle::after {
      margin-left: 15px;
    }
  }

  .custome_droupdown {
    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
      color: #000000;
    }
  }

  .main_listing_table {
    .table > :not(caption) > * > * {
      padding: 0 0.5rem 0.5rem;
    }

    .listing-header {
      // width: 99%;
      display: flex;
      margin: auto;

      .select_group_head {
        flex-grow: 2 !important;
      }
      .card_head_text {
        flex-grow: 1;
        word-break: break-word;
      }
    }

    .listint_border {
      background: #fdfdfd;
      border: 1px solid $dashboard-primary-theme-text !important;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      border-radius: 4px !important;
    }

    .small_textT {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #585656;
      margin-bottom: 18px;
    }

    table tbody tr .card_width {
      max-width: 109px;
    }

    table thead tr th {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      padding-bottom: 18px !important;
      color: #585656;
    }

    tbody tr {
      background: #fdfdfd;
      border: 1px solid $dashboard-primary-theme-text !important;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      // backdrop-filter: blur(20px);
      border-radius: 4px !important;

      .table_body_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #546781;
      }

      td .icon_colour {
        color: #ffcf3d;
      }

      // last code
    }

    // .table>tbody{
    //     display: flex;
    //     flex-direction: column;
    //     gap: 10px;
    //     width: 100%;
    // }
    // tbody, td, tfoot, th, thead, tr {
    //     /* border-color: inherit; */
    //     /* border-style: solid; */
    //     /* border-width: 0; */
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     vertical-align: middle;
    //     align-items: center;
    // }
  }

  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    background: transparent;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: transparent;
    background: transparent;
  }
}
// .closed-list:last-child {
//   margin-bottom: 50px !important;
// }
.check_box {
  .select_item {
    opacity: 1 !important;
    & ~ div {
      opacity: 0.5;
    }
  }
}
.closed-list {
  & ~ div {
    opacity: 0.5;
  }
}
.btn__red {
  background: linear-gradient(
    157.69deg,
    rgba(255, 255, 255, 0.1) 6.02%,
    rgba(255, 255, 255, 0) 95.12%
  );
  border: 2px solid #ff3838;
  box-shadow: 13px 16.5px 15.49px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  &:hover {
    background: #ff3838;
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
    border-radius: 20px;
  }
}

.rating-list {
  margin: auto;
}

.blue__btNN {
  background: #fdfdfd;
  border: 2px solid $dashboard-primary-theme-text;
  box-shadow: 13px 16.5px 15.49px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 4px;

  &:hover {
    background: $dashboard-primary-theme-text;
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
  }
}

.bg-blue_btn {
  background: $dashboard-primary-theme-text;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 7px;
  color: #ffffff;
  border-top: 0px !important;
}

.main_save_listingcard {
  .card_head_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #585656;
    margin-bottom: 18px;

    .input_check {
      padding-left: 0.5px;

      span {
        padding-left: 8px;
      }
    }
  }

  .property-card {
    padding: 2px;
    overflow: hidden;
    // width: 96.28px;
    width: 150px;
    height: auto;

    .property-img {
      height: 43px !important;
      border-radius: 1.89044px;
      overflow: auto;
      height: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .property-detail .property-detail-left {
      gap: 0px;

      p {
        // font-size: 5.26316px;
        line-height: 10px;
        color: #585656;
      }

      .price {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 7.01754px;
        line-height: 7px;
        color: #43a4f6;
      }
    }

    .property-detail .property-detail-right {
      gap: 4px !important;
    }

    button {
      padding: 0px;
      font-size: 7.01754px;
      line-height: 7px;
      padding: 2px;
    }
  }

  .card_inner_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #546781;
    margin-bottom: auto;
    margin-top: auto;
    // display: flex;
    flex-grow: 1;
    // justify-content: flex-start;
  }
  .card_inner_text_select {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #546781;
    // display: flex;
    align-items: center;
    word-wrap: break-word;
    flex-grow: 2 !important;
    // justify-content: flex-start;
  }
  .icon_colour {
    color: #ffcf3d;
  }

  .save_card_border:has(.m_longer_btn) {
    position: relative;
    isolation: isolate;
  }

  .save_card_border {
    // width: 99%;
    margin: auto;
    // background: #fdfdfd;
    border: 1px solid $dashboard-primary-theme-text;
    border-radius: 4px;

    .deactive_card {
      // background: white;
      opacity: 0.5;
      z-index: 1;
    }

    input,
    .main_edit_removepopup,
    .m_longer_btn {
      position: relative;
      z-index: 2;
    }

    .main_edit_removepopup {
      .edit_removepopup {
        top: 3px;
        left: -140px;
        border: 1px solid black;
        width: 150px;
        height: 59px;
        background: red;
      }
    }

    .edit_aero_opsition .tool-aero::after {
      left: 82.5px;
      bottom: 70px !important;
    }

    .delete_droupdown {
      .dropdown-menu {
        min-width: 8rem !important;
      }

      ul {
        li {
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }

          p {
            padding-top: 3.5px;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            vertical-align: middle;
          }
        }
      }
    }

    .dropstart .dropdown-toggle::before {
      content: "";
      border-top: 0em solid transparent;
      border-right: 0em solid;
      border-bottom: 0em solid transparent;
    }
  }

  .m_longer_btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 7px;
    color: #ffffff;
    padding: 8px 32px;
  }

  .Pricing {
    // max-width: 110px;
    padding: 0;
  }

  .rating {
    max-width: 170px;
    // margin: auto;
  }
}

// pop up code

.are_you_sure {
  padding: 42px 32px;

  .are_you_sure {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: $dashboard-primary-theme-text;
  }

  .p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #686868;
  }

  .cancel_btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #686868;
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #686868;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    padding: 125px;
  }
}

.modal_padinG {
  padding: 28px 32px 0px 32px;

  .not_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #546781;
  }
}

.modal_body_padiNG {
  padding: 13px 32px 28px 32px;

  .form-control {
    border: 2px solid $dashboard-primary-theme-text;
  }

  p {
    padding-top: 33px;
    padding-bottom: 12.76px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #546781;
  }

  .character_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #546781;
  }

  .star_color {
    color: #ffcf3d;
    display: flex;
    gap: 15px;
  }

  .cancel_btn {
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #686868;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;

    &:hover {
      background: #686868;
      color: #ffffff;
      border: 2px solid #ffffff;
    }
  }
}

// delete save listing modal
.delete_save_listing {
  .modal-dialog {
    .modal-content {
      background: #fdfdfd;
      border: 1px solid $dashboard-primary-theme-text;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
      backdrop-filter: blur(20px);
      border-radius: 4px;
    }
  }
}

.save_listing_body {
  img {
    height: 60px;
    width: 60px;
  }

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: $dashboard-primary-theme-text;
  }

  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background-image: url(./images/close.svg);
    cursor: pointer;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }

  // cancel btn
  .cancel_btn {
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #686868;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #686868;
    padding-top: 14px;
    padding-bottom: 14px;

    &:hover {
      background: #686868;
      border: 2px solid #fdfdfd;
      color: #fdfdfd;
    }
  }

  .save__btns {
    background: linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
    border: 2px solid #ff3838;
    box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
    border-radius: 2px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ff3838;
    padding-top: 14px;
    padding-bottom: 14px;

    &:hover {
      background: #ff3838;
      border: 2px solid #fdfdfd;
      color: #fdfdfd;
    }
  }
}

// end delete save listing modal
// setting pop up
.setting_Droupdown {
  li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $dashboard-primary-theme-text !important;
    padding: 5px 0px;
    width: 100%;
    text-decoration: none;

    &:hover {
      color: #ffffff !important;
    }
  }

  // a:hover{
  //   color: #FFFFFF !important;
  // }
  li:hover {
    color: #ffffff;
    background-color: $dashboard-primary-theme-text;
  }

  .dropdown-toggle::after {
    content: none;
  }
}
