* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

body {
  font-family: Poppins !important;
}

p {
  margin: 0px !important;
}

img {
  width: 100%;
  object-fit: cover;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .custom-toast {
  border: 2px solid blue;
} */

.custom-progress ::-webkit-progress-value {
  background-color: green;
}

.custom-progress ::-moz-progress-bar {
  background-color: green;
}

/* .span-to-top {
  scroll-snap-align: start;
} */