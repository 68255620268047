.div-main{
    .div-left{
        background-color: #DBEEFD;
        height: 678px;
    }

    .div-right{
        background: url(../../Images/section-one.png);
        height: 601px;
    }

    .div-text{
        max-width: 70%;
        
        .leftparatwo {
            max-width: 50%;
        }
    }
}