@import "../../../../../style/_utilities.scss";

.PaymentHistory_tavSEC {
    padding: 50px 25px;

    .table> :not(:first-child) {
        border-top: 2px solid #315efb !important;
        border-radius: 10px !important;
    }

    .border_none {
        --bs-table-color: var(--bs-body-color);
        --bs-table-bg: transparent;
        --bs-table-border-color: #e4eaef00;
    }

    table {
        font-style: normal;
        font-size: 12px;
        line-height: 12px;
        color: #585656;

        thead tr th {
            font-weight: 500;
            padding-bottom: 17px !important;
        }

        tbody {
            // border: 2px solid #315EFB;
            // border-radius:10px !important;

            tr {
                vertical-align: middle;
                border-bottom: 2px solid #315efb;

                td {
                    font-weight: 300;
                    padding-top: 20px !important;
                    padding-bottom: 20px !important;

                    .btnpaid_pdf {
                        .left_side button {
                            padding: 5px 11px;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 14px;
                            color: #ffffff;
                            background: #2fc40b;
                            border: 1px solid #ffffff;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
    }

    .table_border {
        border: 2px solid #315efb;
        border-radius: 5.6625px;
    }

    .table_border table tbody tr:last-child {
        border-bottom: 2px solid #315dfb00;
    }
}

.paymentTable {
    width: 95%;
    margin: auto;

    table {
        font-size: 12px;
        line-height: 12px;
        color: #585656;

        tr {
            th {
                font-size: 12px !important;
                line-height: 12px !important;
                color: #585656;
                font-weight: normal !important;
            }
        }

        .paid-btn {
            padding: 5px 11px;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            background: #2fc40b;
            border: 1px solid #ffffff;
            border-radius: 2px;
        }

        .statement {
            img {
                width: 25px;
                height: 25px;
            }
        }
    }
}