.Adv_pop_user_pro {
    width: 100%;
    margin-top: 0%;
    position: relative;
    display: flex;

    .adv_content {
        margin: 5% 0% 0%;
        .sy-heading{
            padding-bottom: 13%;
        }
        .point_text {
            display: flex;
            justify-content: center;
            width: 100%;
            .Ad_star_point{
                p{
                    font:600 18px/24px Poppins;
                }
            }
            .fiveStar_Img2 {
                width: 100%;
                padding-top: 15%;
            }
        }

        .app-blue-btn {
            background-color: #43A4F6;
            font: 400 16px/1.2 Poppins;
        }

        .custom_name {
            max-width: 900px;
            margin: auto;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            .smit_name {
                color: #43A4F6;
                padding-top: 8%;
                font-size: 20px;

            }

            .fiveStar_Img {
                max-width: 17%;
            }

            .lorem_text {
                font-size: 20px;
                padding: 4% 0% 0%;

            }

            .user_circle__Img {
                max-width: 120px;
                border: 8px solid white;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                top: -55px;
                left: 43%;
                aspect-ratio: 1/1;
            }
        }
    }
}

@media screen and (min-width: 300px) and (max-width:1000px) {
    .Adv_pop_user_pro {
        width: 100%;
        margin-top: 10%;
        position: relative;
        display: flex;

        .adv_content {
            margin: 0%;

            .sy-heading {
                font-size: 20px;
                padding-bottom: 2%;
            }
            .point_text {
                .fiveStar_Img2 {
                    width: 100%;
                }
            }

            .app-blue-btn {
                background-color: #43A4F6;
                border: none;
                font: 400 16px/1.2 Poppins;
            }

            .custom_name {
                width: 90%;
                margin-top: 10%;

                .smit_name {
                    margin: 4% 0% 0%;
                    font-size: 18px;
                }

                .fiveStar_Img {
                    max-width: 30%;
                }

                .lorem_text {
                    font-size: 17px;
                    padding: 4% 0% 0%;

                }

                .user_circle__Img {
                    width: 70px;
                    top: -24px;
                    left: 40%;
                    aspect-ratio: 1/1;
                }
            }

            h4 {
                font-size: 18px;
            }
            .point_text {
                width: 100%;
                font-size: 18px;
            }
        }
    }
}