// sawan
@import "../../../../style/_utilities.scss";

.main_review_left_card {
  .right_cars {
    padding: 14px 15px;
    margin-bottom: 11px;

    .pro-img {
      width: 60px;
      height: 60px;
    }

    .right_description {
      padding-left: 18px;
      max-width: 88%;

      .tool_trip svg {
        font-size: 10px;
        color: #FFCF3D;
      }

      .date {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 10px;
        color: $dashboard-primary-theme-text;
      }
    }

    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 8px;
      color: $dashboard-primary-theme-text;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: #585656;
    }
  }
}

// tooltrip code
.tool_trip {
  .tooltrip_pading {
    padding: 10px 18px;

    .like_text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 10px;
      color: #585656;
    }

    .star_orange svg {
      color: #ffcf3d;
      font-size: 10px;
    }
  }

  .suspend-tooltip {
    width: 230px;
    border: 1px solid $dashboard-primary-theme-text;
    opacity: 0;
    box-shadow: 0px 0px 16px rgb(34 34 34 / 35%);
    top: -46px;
    flex-grow: 1;
    background: #ffffff;
    left: 0px;


    .tip {
      width: 10px;
      height: 10px;
      position: absolute;
      /* right: 49px; */
      rotate: 134deg;
      transform: rotateX(38deg);
      transform: translateY(-556%);
      border-right: 1px solid $dashboard-primary-theme-text;
      border-top: 1px solid $dashboard-primary-theme-text;
      background: white;
      left: -19px;
      bottom: 33px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: #585656;
      padding: 8px 12px;
    }
  }

  &:hover {
    .suspend-tooltip {
      opacity: 1;
      background: $white;
    }
  }
}