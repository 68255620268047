@import '../../../../../style/utilities.scss';

.recent-listing-sec {
    padding-left: 20px;
    // margin-top: 50px;
    padding-top: 19px;

}

.recent-listing-heading {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    color: $homepage-text;
}

.card-slider {
    overflow-x: scroll;
    gap: 48px;
    scroll-behavior: smooth;
}

.card-slider-box {
    .listing-subheading {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
    }
}