.Avd-main-container {
    max-width: 90%;
    margin: 0 auto;
}

.text-content {
    width: 60%;
    margin: 0% 5% 5%;

    .app-blue-btn {
        background-color: #43A4F6;
        border-radius: 5px;
        padding: 15px;
        border: none;
        font: 400 16px/1.2 Poppins;
    }
}

.Advertise {
    position: relative;
    min-height: 838px;
    background-image: linear-gradient(90deg, #DBEEFD 50%, #fff 50%);
    isolation: isolate;
    padding-right: 4%;
    display: flex;
    align-items: center;

    h1 {
        font-weight: 600;
    }

    .First_text {
        padding-right: 50%;
    }
}

.Advertise img {
    position: absolute;
}

.Advertise .home {
    right: 0;
    max-width: 57%;
    bottom: 8%;
    z-index: -1;
    max-height: 762px;
}

.Advertise .dowtImg1 {
    left: 48%;
    bottom: 0;
    max-width: 23%;
    transform: translateX(-50%);
    z-index: -2;
    border: none;
}

// .ad_hero {
//     background-image: url(../ImgAdv/home_img.png);
//     background-position: center;
//     background-repeat: no-repeat;
//     width: 100%;
//     height: calc(100vh - 92px);
//     margin-right: 56px;
//     padding-left: 56px;
//     // flex-wrap: wrap;

//     .hero_left {
//         flex: 2 1 300px;
//         align-items: center;
//         justify-content: center;
//         display: flex;
//         height: 100%;

//         .hero_heading {
//             font: 600 34px/45px Poppins;
//             top: 16%;
//             width: max-content;
//             max-width: 60vw;
//             left: 0px;

//         }

//     }

//     .hero_right {
//         flex: 3 1 300px;
//         height: 85%;
//         object-fit: cover;
//     }
// }

@media screen and (min-width: 300px) and (max-width:1000px) {
    .Advertise {
        justify-content: center;
        display: block;
        width: 100%;
        background-image: linear-gradient(90deg, #DBEEFD 100%, #fff 0%);

        .text-content {
            width: 100%;
            // width: clamp(500px, 60%, 100%);
            padding: 0% 3% 5%;

            .First_text {
                padding-right: 0%;
                margin-top: 0px;
                // 
                
            }

            .app-blue-btn {
                background-color: #43A4F6;
                border-radius: 5px;
                max-width: 200px;
                padding: 15px;
                border: none;
                font: 400 16px/1.2 Poppins;
            }
        }

        h1 {
            font-weight: 800;
            font-size: 20px;
            padding-top: 5%;
        }

        img {
            position: unset;
        }

        .home {
            max-width: 100%;
            bottom: 0%;
            z-index: 0;
            max-height: 1000px;
            position: unset;
            left: 5px;

        }

        .dowtImg1 {
            left: 10%;
            bottom: 0;
            max-width: 100%;
            transform: translateX(-50%);
            z-index: -2;
            border: none;
            position: unset;
            display: none;
        }


    }
}