/* === Wrapper Styles === */
@import "../../../style/utilities.scss";

#FileUpload {
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  // margin: 15px;
  padding: 10px;
  // width: 100%;
  // height: 80%;
  border-radius: 4px;
  border: 1px solid #315efb;
  background: #fdfdfd;
  // box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.35);
  // backdrop-filter: blur(20px);
  height: calc(100vh - 160px);
  margin-top: 20px;
}

/* === Upload Box === */
.upload {
  margin: 10px;
  height: 85px;
  border: 5px dashed #e6f5e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.upload p {
  margin-top: 12px;
  line-height: 0;
  font-size: 22px;
  color: #0c3214;
  letter-spacing: 1.5px;
}

.upload_bar {
  margin-top: 2.5rem;
  margin-left: 2rem;
  width: 100%;

  .upload_bar__title {
    color: #315efb;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 77.778% */
  }
}

.upload_bar div {
  margin-top: 2rem;
}

.progress {
  margin-top: 10px;
}

.progress_bar {
  width: 100%;
  margin-top: 5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  height: 50vh;
  position: relative;
  overflow-y: scroll;

  .uploading {
    margin: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .progress,
    .progress-stacked {
      --bs-progress-bg: none;
      --bs-progress-height: 0.5rem;
    }

    .file {
      width: 100%;
    }
  }

  // .uploading:hover {
  //   border-radius: 4px;
  //   border: 1px solid #315efb;
  //   background: #fdfdfd;
  //   box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
  //   backdrop-filter: blur(75px);
  //   padding: 10px;
  // }

  .uploaded__bottom {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    position: sticky;
    bottom: 0;
    width: -webkit-fill-available;

    .cancel_btn {
      width: 45%;
      border-radius: 20px;
      border: 2px solid #9c9c9c;
      font-family: "Poppins";
      background: #fdfdfd;
      box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(75px);
      color: #9c9c9c;
    }

    .send_btn {
      width: 45%;
      border-radius: 20px;
      font-family: "Poppins";
      border: 2px solid #315efb;
      background: #fdfdfd;
      box-shadow: 13px 16.5px 15.489999771118164px -10.859999656677246px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(75px);
      color: #315efb;
    }
  }
}

.upload__button {
  background-color: #e6f5e9;
  border-radius: 10px;
  padding: 0px 8px 0px 10px;
}

.upload__button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.custom-dropzone {
  border-radius: 4px;
  border: 2px dashed #315efb !important;
  background-color: #e2eaff;
  position: relative;
  background: url("./images/icon-upload-download.png");
  background-position: center;
  background-color: #e2eaff !important;
  background-repeat: no-repeat;
  height: 50vh;

  .MuiBox-root {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);

    p {
      width: 250px;
      margin-bottom: 20px !important;
      color: #848484;
      text-align: center;
      font-family: Poppins;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 115.789% */
    }

    svg {
      display: none;
    }
  }

  .MuiBox-root,
  .MuiBox-root {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: calc(50vh - 5px);
    }
  }
}

/* === Uploaded Files === */
.file {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.file__name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 300px;
  color: #0c3214;
  font-size: 14px;
  letter-spacing: 1.5px;
}

.fa-times:hover {
  cursor: pointer;
  opacity: 0.8;
}

.fa-file-pdf {
  padding: 15px;
  font-size: 40px;
  color: #0c3214;
}

.send-review {
  width: 90%;

  button {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    background: #315efb;
    padding: 10px;
    width: 100%;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
}
