@import "../../../../../style/_utilities.scss";

.plain_and_billing_section {
    .plan_sec {
        padding: 1.5625rem 3.4375rem;

        .bill-content {
            color: $dashboard-primary-theme-text;
            padding-bottom: 1.75rem !important;
        }

        .main_content {

            .Current_Active_text {
                color: #585656;

                .left_side p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 14px;
                }

                .right_side {
                    font-size: 16px;
                    line-height: 14px;

                }
            }

            // p {
            //   font-weight: 500;
            //   font-size: 16px;
            //   line-height: 14px;
            //   color: #585656;
            // }

            .Neighborhood_padtop {
                margin-top: 30px;

                .border-bottom {
                    padding-bottom: 24px;
                    border-bottom: 2px solid $dashboard-primary-theme-text !important;

                }

                .left_side {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 14px;
                    color: #585656;
                }

                span {
                    color: #585656;
                }

                .Under_btn {
                    padding: 8px 30px;
                    font-size: 14px;
                    line-height: 14px;
                }
            }

            .Last_Payment_block {
                padding-top: 23px;


                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 14px;
                    color: #585656;
                }

                .left_side {
                    padding-right: 2.3125rem;
                    display: flex;
                    flex-direction: column;
                    gap: 29px;
                    border-right: 2px solid $dashboard-primary-theme-text;
                }

                .right_side {
                    padding-left: 2.3125rem;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                }
            }



            .payment_status {
                border: 1px solid #ffffff;
                color: #fff;
                border-radius: 2px;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                padding: 0.3125rem 1.3125rem;

                &.upcoming {
                    background: #FF5C00;
                }

                &.paid {
                    background: #2FC40B;
                }
            }

            .border_left {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 20px;
                    top: 20px;
                    left: 0;
                    border: 1px solid #fff;
                }
            }

            .border_right {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 20px;
                    top: 20px;
                    right: 0;
                    border: 1px solid #fff;
                }
            }

        }

        .two_btns {
            padding-top: 2rem;
            padding-bottom: 2rem;

            .btns_border {
                box-shadow: 1px 7px 16px rgba(34, 34, 34, 0.35);
                border-radius: 2px;
                padding: 0.6125rem 0rem;
                border: 2px solid #FFFFFF;
                color: #FFFFFF;

                &.upgrad_btn {
                    background: linear-gradient(0deg, #2FC40B, #2FC40B), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
                }

                &.upcoming_btn {
                    background: linear-gradient(0deg, #FF3838, #FF3838), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
                }
            }

            .left_side {
                padding-right: 49px;

            }

            .right_side {
                padding-right: 34px;
                padding-left: 25px;
            }
        }

        .main_del_btn {

            .suspend .suspend-tooltip {
                right: 0;
                top: -67px;
                z-index: 1;
                background-color: #FFFFFF !important;

            }

            .suspend .suspend-tooltip .tip {
                rotate: 134deg;
                transform: rotateX(0deg);
                transform: translateY(-8%);
                top: 43px;
                right: 6px;
            }

            .delete_trip .suspend-tooltip {
                right: -12px;
                top: -68px;
                background-color: #FFFFFF;

                .tip {
                    /* right: 10px; */
                    rotate: 133deg;
                    transform: rotateX(45deg);
                    transform: translateY(-83%);
                    bottom: 0px;
                    right: 18px;
                }
            }


        }

        .suspend_btn,
        .Delete_btn {
            padding: 0.75rem 6.125rem;
            margin-right: 8px;
        }

    }
}

.data-not-found {
    color: $dashboard-primary-theme-text;
    text-align: center;
    margin-top: 15px;
}