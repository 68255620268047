.bsModal_header {
    button {
        padding: 0;
        padding-top: 25px;
        padding-right: 29px;
    }
}

.openHouseBody {
    .heading {
        font-size: 18px;
        color: #315efb;
    }

    .activeDay {
        background: #315efb;
        border: 2px solid #fdfdfd;
        color: #fdfdfd !important;
    }
}