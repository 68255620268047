@import "../../../../../style/utilities.scss";

.hero-sec {
  // min-height: 40.625rem;
  min-height: 100vh;
  gap: 50px;
  // min-height: calc(100vh - 91px);
  // background-image: url(./image/herobg.jpg);
  // background-size: cover;
  // background-repeat: no-repeat;
  // gap: 4.625rem;
  // animation: image 15s infinite ease-in;
  // animation: image 8s infinite alternate;
  // transition: all 15s ease-in-out;
  overflow: hidden;

  @keyframes image {
    0% {
      background-image: url("https://unsplash.it/1900/604");
      // background-image: url('https://fastly.picsum.photos/id/53/1900/600.jpg?hmac=ECIR8B_j2BmnlFp1B2dkJT58uLwGYZ-LWz75Mcvy2zM');
      // background-size: 110%;
      transform: scale(1);
    }

    25% {
      // background-image: url('https://fastly.picsum.photos/id/828/1900/601.jpg?hmac=YoCPP7mqs2_Q8PW5a0KQ5udliKdzBVKt96NwW8W3o8c');
      background-image: url("https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1296&q=80");
      // background-image: url('https://unsplash.it/1900/600');
      transform: scale(1.3);
    }

    50% {
      // background-image: url('https://fastly.picsum.photos/id/53/1900/600.jpg?hmac=ECIR8B_j2BmnlFp1B2dkJT58uLwGYZ-LWz75Mcvy2zM');
      background-image: url("https://unsplash.it/1900/601");
      // background-image: url(./image/herobg.jpg);
      transform: scale(1.6);
    }

    75% {
      background-image: url("https://unsplash.it/1900/602");
      // background-image: url('https://fastly.picsum.photos/id/426/1900/601.jpg?hmac=PEdvMWDbIlLNAXb64vxeP20b0S77eK8qDtN8ZFFek_g');
      transform: scale(1.9);
    }

    100% {
      background-image: url("https://fastly.picsum.photos/id/817/1900/600.jpg?hmac=lYMJTWp-CmmXbEa0u6CjjU1ce9c0XM-ePunz4EBScuI");
      transform: scale(2.2);
    }
  }

  .bgImg-animation {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image: url(./image/herobg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    // animation: image 20s infinite ease-in-out;
    // transition: all 20s ease-in-out;
    z-index: -1;

    .videoTag {
      object-fit: fill;
    }
  }

  .tag-line {
    color: $white;
    max-width: 710px;
    gap: 28px;
    font-family: Inter;

    h1 {
      font-weight: 600;
      font-size: 5.2rem;
      line-height: 5.2rem;
      text-shadow: -6px 6px 3px #0e0d0d;
    }

    p {
      text-shadow: -3px 3px 3px #0e0d0d;
      font-weight: 600;
      font-size: 2.125rem;
      // line-height: 2rem;
      // text-transform: capitalize;
    }
  }

  .hero-filter {
    // max-width: 78.3125rem;
    min-height: 11.25rem;
    // background: rgba(142, 202, 226, 0.7);
    border-radius: 0.3125rem;
    // padding: 1.375rem 1.9375rem;
    gap: 2rem;

    .property-category {
      gap: 2.5rem;

      .user-type {
        background: $home-primary;
        color: $white;
      }

      div {
        // width: 15.3125rem;
        padding: 25px 35px;
        height: 3.0625rem;
        background: $white;
        border: 2px solid $white;
        border-radius: 60rem;
        transition: all 0.25s ease-in-out;

        &:hover {
          background: $home-primary;
          color: $white;
        }

        p {
          font-family: "Comfortaa";
          font-weight: 700;
          font-size: 1.375rem;
          line-height: 1.375rem;
        }
      }
    }

    .hero-property-serach {
      // max-width: 85%;
      align-items: center;
      max-width: 73rem;
      min-height: 6rem;
      background: $white;
      // border-radius: 0.3125rem;
      border-radius: 15rem;
      padding: 1rem 1.2rem 1rem 3.5rem;

      .filter-input {
        // column-gap: 5.9375rem;
        gap: 6px;
        column-gap: 75px !important;

        .one-field {
          display: flex;
          flex-direction: column;
          gap: 12px;

          input {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);
            padding-bottom: 0.375rem;
            border-bottom: 0.0625rem solid #a4a1a1 !important;
          }

          .hero-select {
            width: fit-content !important;
            min-width: 100px;
          }
        }

        .titile {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 0.875rem;
        }

        .typeRError {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 0.875rem;
          color: red;
        }

        .Location {
          max-width: 10.0625rem;

          .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root
            .MuiAutocomplete-inputRoot {
            padding-right: 0px;
          }
        }

        .Category {
          max-width: 6rem;
        }

        .Type {
          max-width: 6.4375rem;
        }

        .Price {
          max-width: 200px;

          div {
            gap: 38px;
          }

          input {
            max-width: 77px;
          }
        }
      }
    }

    .filter-button {
      gap: 1.1875rem;
      height: 61px;

      div {
        aspect-ratio: 1/1;
        // width: 3.0625rem;
        // height: 3.0625rem;
        // border-radius: 0.3125rem;
        background: $home-primary;
        border-radius: 50%;

        img {
          width: 36px;
          aspect-ratio: 1/1;
        }
      }

      button {
        font-family: "Comfortaa";
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.25rem;
        background: $home-primary;
        // height: 3.0625rem;
        // padding: 0 35px;
        aspect-ratio: 1/0.3;
        border-radius: 30px;
        border: none;
      }
    }
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hide-downBtn {
  .css-2iz2x6 {
    display: none !important;
  }

  .css-12rx5qu::before,
  .css-12rx5qu::after {
    display: none !important;
  }

  .css-1q60rmi-MuiAutocomplete-endAdornment,
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon,
  .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
    display: none !important;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot {
    padding: 0px !important;
  }

  .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 0px !important;
  }

  .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 0px !important;
  }

  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    padding-right: 0;
  }

  // .css-yf6fzd-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  //     width: fit-content !important;
  //     background: red !important;
  // }
}
.hide-downBtn div {
  z-index: 10000;
}

.MuiPopper-root {
  z-index: 100000;
}
