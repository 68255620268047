
@import "../../../../../../../style/_utilities.scss";
.daily_tab_section{
    .row_heading{
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $dashboard-primary-theme-text;
        margin-top: 29px !important;
        margin-bottom: 18px;
        width: 100%;
        display: flex;
        padding-inline-end: 10px;
        .col_width{
            width: 33%;
            padding-left: 24px;
        }
    }

    .row_headings{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        width: 100%;
        display: flex;
        .col_width{
            width: 33%;
            padding-left: 24px;
        }
    }
    
    .WEElks_outer_border{
        border: 2px solid $dashboard-primary-theme-text;
        border-radius: 5.6625px 5.6625px 5.66px 5.66px;
        
        
        .pad_top_bottom{
            // padding: 20px 0 22px 24px;
            border-bottom: 2px solid $dashboard-primary-theme-text;
            padding-top: 20px;
            padding-bottom: 16px;
        }

        .pad_top_bottom:last-child{
            border-bottom: 1px solid rgba(255, 0, 0, 0);
        }
    }
}


.scrolls_stopwatch{
        max-height: 430px;
        overflow-y: scroll;
        scroll-behavior: smooth;
        padding-inline-end: 10px;
    
        &::-webkit-scrollbar {
            width: 6.62px;
        }
    
        &::-webkit-scrollbar-track {
    
            background: $white;
            border-radius: 94.5483px;
            position: relative;
        }
    
        &::-webkit-scrollbar-thumb {
            width: 4.73px;
            background: #315EFB;
            border-radius: 94.5483px;
        }
    }
