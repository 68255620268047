@import "../../../../../style/_utilities.scss";



.AddEmployee_section {
    padding: 20px 35px 0;

    .table {
        border-collapse: separate;
        // border-spacing: 0 20px;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;

        thead tr th {
            flex: 1;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: $dashboard-primary-theme-text;
            padding-bottom: 0px;
            vertical-align: top;
        }

        tbody {
            display: block;
            width: 100%;
            overflow: auto;
            max-height: 72vh;
        }

        tr {
            display: flex;
            align-items: center;
        }

        tbody tr {

            // background: #fdfdfd;
            // outline: 1px solid #315efb;
            // box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
            // border-radius: 4px !important;
            .address_width {
                width: 100px;
                display: table-cell;
            }

            td {
                display: flex;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #585656;
            }

            td {
                flex: 1;
            }

            th {
                flex: 1;
            }

        }
    }
}