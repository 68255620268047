@import '../../../style/utilities.scss';

.agentbiomain {
    // max-height: 580px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0px;
    }

    .agentbiomain-container {
        // max-height: 80vh;
        overflow-y: scroll;
        scroll-behavior: smooth;

        .table-btn {
            button {
                min-width: auto;
                height: auto;
            }
        }

        .back-btn {
            background: $dashboard-primary-theme-text;
            width: 25px;
            aspect-ratio: 1/1;
            border-radius: 50%;

            svg {
                color: #ffffff;
            }
        }
    }

    .headimg {
        height: 200px;
        // width: 1153px;
        position: relative;
    }

    img.profile {
        max-width: 127px;
        aspect-ratio: 1/1;
        left: 2rem;
        top: 7rem;
        position: absolute;
        border: 2px solid gray;
        border-radius: 50%;
    }

    .agentprofile_name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height, or 100% */

        color: #585656;
        position: relative;
        top: 2rem;
        left: -30rem;
    }

    .profile_counter_count,
    .agentaboutbio {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */
        margin-top: 1rem;
        color: #4e62b1;
        margin-left: 0.2rem;
    }

    .profile_counter_head {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */
        color: #585656;
        margin-left: 0.2rem;
    }

    .profile_counter {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 337px;

        position: relative;
        left: 50rem;
        padding: 10px;
        top: -0.5rem;
    }

    .profile_counter_head_count {
        display: flex;
        flex-direction: column;
    }

    .agentprofile_job {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        /* identical to box height, or 100% */
        position: relative;
        top: 2.5rem;
        left: -28rem;
        color: #4e62b1;
    }

    .agentprofiles {
        display: flex;
        flex-direction: column;
    }

    .agentbio_tabs {
        position: relative;
        // top: 2rem;
        // left: 1rem;
    }

    .agentbio_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: $repeated-text-color;
    }


    .nav-tabs .nav-link {
        border: none;
        outline: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $repeated-text-color;
    }

    ul.mb-3.nav.nav-tabs {
        border: none;
    }

    .nav-tabs .nav-link.active {
        background: transparent;
        outline: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $repeated-text-color;
        border-bottom: 3px solid $repeated-text-color;
    }
}

.agen-profile-listing {
    padding-left: 35px;
    // margin-top: 4.5rem !important;

    .profile-name {
        h6 {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: $lite-black;
        }

        p {
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            color: $repeated-text-color;
        }
    }

    .listing {
        gap: 32px;
    }

    .listing-info {
        gap: 10px;

        p {
            font-size: 14px;
            line-height: 14px;
            color: $lite-black;
        }

        .listing-info-price {
            color: $repeated-text-color;
        }
    }
}

.current-map-view {
    height: -webkit-fill-available;

    .agent-profile-mapview {
        z-index: 890;
        top: 85px;
        left: 10px;

        .profile-pic {
            border: 2px solid #ffffff;
            width: 100px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
        }

        .name {
            font-weight: 500;
            // color: #ffffff;
        }

        .lience {
            font-size: 12px;
            font-weight: 500;
        }
    }
}